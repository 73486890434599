import React from 'react';
import { glowAnimation } from '../commonStyles';

export const Rainbow = () => {
  return (
    <>
      <g filter='url(#filter2_f_38_451)' className={glowAnimation}>
        <path
          d='M502 438.847L1157.67 552.312V597.017L502 483.546'
          fill='url(#paint4_linear_38_451)'
          mask='url(#rainbow-mask)'
        />
        <path
          d='M502 394.142L1157.67 507.612V552.312L502 438.847V438.836V394.142Z'
          fill='url(#paint5_linear_38_451)'
          mask='url(#rainbow-mask)'
        />
        <path
          d='M502 349.442L1157.67 462.907V507.612L502 394.142V394.12V349.442Z'
          fill='url(#paint6_linear_38_451)'
          mask='url(#rainbow-mask)'
        />
        <path
          d='M502 304.71V304.737L1157.67 418.207V462.907L502 349.442V349.41V304.71Z'
          fill='url(#paint7_linear_38_451)'
          mask='url(#rainbow-mask)'
        />
        <path
          d='M502 260V260L1157 373V418L502 304V304V260Z'
          fill='url(#paint8_linear_38_451)'
          mask='url(#rainbow-mask)'
        />
      </g>

      <path
        d='M1192.37 603.879L536.015 490.38V445.662L1192.37 559.166'
        fill='url(#paint9_linear_38_451)'
        mask='url(#rainbow-mask)'
      />
      <path
        d='M1192.37 559.16L536.015 445.657V400.944L1192.37 514.442V514.453V559.16Z'
        fill='url(#paint10_linear_38_451)'
        mask='url(#rainbow-mask)'
      />
      <path
        d='M1192.37 514.448L536.015 400.949V356.231L1192.37 469.735V469.756V514.448Z'
        fill='url(#paint11_linear_38_451)'
        mask='url(#rainbow-mask)'
      />
      <path
        d='M1192.37 469.729V469.703L536.015 356.199V311.486L1192.37 424.985V425.017V469.729Z'
        fill='url(#paint12_linear_38_451)'
        mask='url(#rainbow-mask)'
      />
      <path
        d='M1192.37 425.017V424.979L536.015 311.476V266.763L1192.37 380.261V380.304V425.017Z'
        fill='url(#paint13_linear_38_451)'
        mask='url(#rainbow-mask)'
      />

      <defs>
        <filter id='filter2_f_38_451' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImage' />
          <feGaussianBlur stdDeviation='37.5' result='effect1_foregroundBlur_38_451' />
        </filter>
        <linearGradient
          id='paint4_linear_38_451'
          x1='472'
          y1='439.299'
          x2='1181'
          y2='554.799'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.526042' stopColor='#FF5457' />
          <stop offset='0.9375' stopColor='#FF5457' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint5_linear_38_451'
          x1='519'
          y1='394.299'
          x2='1187'
          y2='518.799'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.588542' stopColor='#FFBD00' />
          <stop offset='1' stopColor='#FFBD00' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint6_linear_38_451'
          x1='502'
          y1='355.299'
          x2='1197'
          y2='465.799'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.630208' stopColor='#00FF75' />
          <stop offset='1' stopColor='#00FF75' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint7_linear_38_451'
          x1='502'
          y1='288.799'
          x2='1208.5'
          y2='393.299'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.390625' stopColor='#00EFFF' />
          <stop offset='1' stopColor='#00EFFF' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint8_linear_38_451'
          x1='502'
          y1='244.799'
          x2='1158'
          y2='252.799'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.645833' stopColor='#6D99FF' />
          <stop offset='0.953125' stopColor='#6D99FF' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint9_linear_38_451'
          x1='872.167'
          y1='528.085'
          x2='216.762'
          y2='391.522'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF5457' stopOpacity='0' />
          <stop offset='0.4' stopColor='#FF5457' />
          <stop offset='0.41' stopColor='#FF5457' stopOpacity='0.98' />
          <stop offset='0.63' stopColor='#FF5457' stopOpacity='0.57' />
          <stop offset='0.81' stopColor='#FF5457' stopOpacity='0.26' />
          <stop offset='0.94' stopColor='#FF5457' stopOpacity='0.07' />
          <stop offset='1' stopColor='#FF5457' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint10_linear_38_451'
          x1='863.229'
          y1='481.546'
          x2='207.824'
          y2='344.983'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFBD00' stopOpacity='0' />
          <stop offset='0.4' stopColor='#FFBD00' />
          <stop offset='0.41' stopColor='#FFBD00' stopOpacity='0.98' />
          <stop offset='0.63' stopColor='#FFBD00' stopOpacity='0.57' />
          <stop offset='0.81' stopColor='#FFBD00' stopOpacity='0.26' />
          <stop offset='0.94' stopColor='#FFBD00' stopOpacity='0.07' />
          <stop offset='1' stopColor='#FFBD00' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint11_linear_38_451'
          x1='854.29'
          y1='435.023'
          x2='198.885'
          y2='298.46'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#00FF75' stopOpacity='0' />
          <stop offset='0.4' stopColor='#00FF75' />
          <stop offset='0.41' stopColor='#00FF75' stopOpacity='0.98' />
          <stop offset='0.63' stopColor='#00FF75' stopOpacity='0.57' />
          <stop offset='0.81' stopColor='#00FF75' stopOpacity='0.26' />
          <stop offset='0.94' stopColor='#00FF75' stopOpacity='0.07' />
          <stop offset='1' stopColor='#00FF75' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint12_linear_38_451'
          x1='845.351'
          y1='388.458'
          x2='189.946'
          y2='251.894'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#00EFFF' stopOpacity='0' />
          <stop offset='0.4' stopColor='#00EFFF' />
          <stop offset='0.41' stopColor='#00EFFF' stopOpacity='0.98' />
          <stop offset='0.63' stopColor='#00EFFF' stopOpacity='0.57' />
          <stop offset='0.81' stopColor='#00EFFF' stopOpacity='0.26' />
          <stop offset='0.94' stopColor='#00EFFF' stopOpacity='0.07' />
          <stop offset='1' stopColor='#00EFFF' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint13_linear_38_451'
          x1='836.412'
          y1='341.924'
          x2='181.007'
          y2='205.361'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#6D99FF' stopOpacity='0' />
          <stop offset='0.22' stopColor='#6D99FF' stopOpacity='0.55' />
          <stop offset='0.4' stopColor='#6D99FF' />
          <stop offset='0.41' stopColor='#6D99FF' stopOpacity='0.98' />
          <stop offset='0.63' stopColor='#6D99FF' stopOpacity='0.57' />
          <stop offset='0.81' stopColor='#6D99FF' stopOpacity='0.26' />
          <stop offset='0.94' stopColor='#6D99FF' stopOpacity='0.07' />
          <stop offset='1' stopColor='#6D99FF' stopOpacity='0' />
        </linearGradient>
      </defs>
    </>
  );
};
