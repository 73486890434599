import React from 'react';

export const Ebooks = ({ className }) => {
  return (
    <>
      <g className={className}>
        <path
          d='M31.8933 5.74382L12.1328 0.548636C11.2634 0.320062 10.3715 0.83263 10.1406 1.69349L1.93671 32.2873C1.70586 33.1482 2.22352 34.0313 3.09293 34.2599L22.8534 39.4551C23.7228 39.6837 24.6147 39.1711 24.8456 38.3102L33.0495 7.71641C33.2803 6.85555 32.7627 5.97239 31.8933 5.74382Z'
          fill='#4F4FC1'
        />
        <path
          d='M31.8913 5.74382L12.1308 0.548636C11.2614 0.320062 10.3695 0.83263 10.1387 1.69349L1.93475 32.2873C1.70391 33.1482 2.22157 34.0313 3.09098 34.2599L22.8514 39.4551C23.7208 39.6837 24.6128 39.1711 24.8436 38.3102L33.0475 7.71641C33.2784 6.85555 32.7607 5.97239 31.8913 5.74382Z'
          fill='url(#paint0_linear_3544_56286)'
        />
        <path
          d='M30.0104 5.25065L10.25 0.055472C9.38058 -0.173102 8.48864 0.339466 8.2578 1.20033L0.0538935 31.7941C-0.176951 32.655 0.340708 33.5382 1.21012 33.7667L20.9706 38.9619C21.84 39.1905 22.7319 38.6779 22.9628 37.8171L31.1667 7.22325C31.3975 6.36239 30.8799 5.47923 30.0104 5.25065Z'
          fill='#7E98FD'
        />
        <path
          d='M28.5739 6.03523L11.1074 1.44317C10.238 1.21459 9.34607 1.72716 9.11522 2.58802L1.49394 31.0091C1.26309 31.87 1.78075 32.7532 2.65016 32.9817L20.1166 37.5738C20.986 37.8024 21.8779 37.2898 22.1088 36.4289L29.7301 8.00783C29.9609 7.14697 29.4433 6.26381 28.5739 6.03523Z'
          fill='#FFAAA0'
        />
        <path
          d='M28.5739 6.03523L11.1074 1.44317C10.238 1.21459 9.34607 1.72716 9.11522 2.58802L1.49394 31.0091C1.26309 31.87 1.78075 32.7532 2.65016 32.9817L20.1166 37.5738C20.986 37.8024 21.8779 37.2898 22.1088 36.4289L29.7301 8.00783C29.9609 7.14697 29.4433 6.26381 28.5739 6.03523Z'
          fill='url(#paint1_linear_3544_56286)'
        />
        <path
          d='M26.3103 8.63043L11.9454 4.85379C11.2658 4.67512 10.5686 5.07578 10.3881 5.7487L7.7424 15.6151C7.56195 16.288 7.96659 16.9783 8.64619 17.157L23.0111 20.9336C23.6907 21.1123 24.3879 20.7116 24.5683 20.0387L27.2141 10.1724C27.3945 9.49945 26.9899 8.80911 26.3103 8.63043Z'
          fill='#7E98FD'
        />
        <mask
          id='mask0_3544_56286'
          style={{ maskType: 'luminance' }}
          maskUnits='userSpaceOnUse'
          x='7'
          y='4'
          width='21'
          height='17'
        >
          <path
            d='M26.3103 8.63043L11.9454 4.85379C11.2658 4.67512 10.5686 5.07578 10.3881 5.7487L7.7424 15.6151C7.56195 16.288 7.96659 16.9783 8.64619 17.157L23.0111 20.9336C23.6907 21.1123 24.3879 20.7116 24.5683 20.0387L27.2141 10.1724C27.3945 9.49945 26.9899 8.80911 26.3103 8.63043Z'
            fill='white'
          />
        </mask>
        <g mask='url(#mask0_3544_56286)'>
          <path
            d='M8.51764 12.7252L14.1039 8.63672L18.642 20.2767L7.29297 17.2925L8.51764 12.7252Z'
            fill='#FFAAA0'
          />
          <path
            d='M8.51764 12.7252L14.1039 8.63672L18.642 20.2767L7.29297 17.2925L8.51764 12.7252Z'
            fill='url(#paint2_linear_3544_56286)'
          />
          <path
            d='M17.873 18.3013L22.5293 15.3799L25.0992 21.9749L18.583 20.2612L17.873 18.3013Z'
            fill='#FF9093'
          />
          <path
            d='M19.2046 17.4648L20.4181 20.7432L18.67 20.284L17.873 18.3008L19.2046 17.4648Z'
            fill='#FF6E75'
          />
        </g>
        <path
          d='M22.7202 13.2014C23.6465 13.2014 24.3974 12.4578 24.3974 11.5406C24.3974 10.6234 23.6465 9.87988 22.7202 9.87988C21.7939 9.87988 21.043 10.6234 21.043 11.5406C21.043 12.4578 21.7939 13.2014 22.7202 13.2014Z'
          fill='#00FF80'
        />
        <path
          d='M22.638 24.4076L7.39361 20.4004C7.24566 20.361 7.15738 20.2108 7.19717 20.0643C7.23695 19.9178 7.38864 19.8304 7.53659 19.8698L22.7809 23.877C22.9289 23.9164 23.0172 24.0666 22.9774 24.2131C22.9376 24.3596 22.7859 24.447 22.638 24.4076Z'
          fill='#4F4FD2'
        />
        <path
          d='M21.9367 27.0209L6.69232 23.0137C6.54436 22.9743 6.45609 22.8241 6.49587 22.6776C6.53566 22.5311 6.68734 22.4437 6.8353 22.4831L22.0796 26.4903C22.2276 26.5297 22.3159 26.6799 22.2761 26.8264C22.2363 26.9729 22.0846 27.0603 21.9367 27.0209Z'
          fill='#4F4FD2'
        />
        <path
          d='M21.2355 29.6352L5.99114 25.6279C5.84319 25.5885 5.75491 25.4384 5.7947 25.2919C5.83449 25.1454 5.98617 25.0579 6.13413 25.0973L21.3785 29.1046C21.5264 29.1439 21.6147 29.2941 21.5749 29.4406C21.5351 29.5871 21.3834 29.6745 21.2355 29.6352Z'
          fill='#4F4FD2'
        />
        <path
          d='M29.424 24.2649L19.3239 21.6095C18.7923 21.4697 18.2469 21.7832 18.1058 22.3095L13.8698 38.1062C13.7286 38.6326 14.0452 39.1726 14.5768 39.3123L24.6769 41.9677C25.2085 42.1075 25.7539 41.7941 25.895 41.2677L30.131 25.4711C30.2721 24.9447 29.9556 24.4047 29.424 24.2649Z'
          fill='#F5F5F5'
        />
        <path
          d='M29.424 24.2649L19.3239 21.6095C18.7923 21.4697 18.2469 21.7832 18.1058 22.3095L13.8698 38.1062C13.7286 38.6326 14.0452 39.1726 14.5768 39.3123L24.6769 41.9677C25.2085 42.1075 25.7539 41.7941 25.895 41.2677L30.131 25.4711C30.2721 24.9447 29.9556 24.4047 29.424 24.2649Z'
          fill='url(#paint3_linear_3544_56286)'
        />
        <path
          d='M24.1918 36.744L17.8931 35.0882C17.7651 35.0549 17.6892 34.9244 17.7228 34.7976C17.7564 34.6708 17.8882 34.5957 18.0162 34.629L24.3149 36.2848C24.4429 36.318 24.5188 36.4485 24.4852 36.5753C24.4516 36.7021 24.3198 36.7772 24.1918 36.744Z'
          fill='#4F4FD2'
        />
        <path
          d='M23.7289 38.4676L17.4302 36.8118C17.3022 36.7785 17.2263 36.6481 17.2599 36.5213C17.2935 36.3944 17.4253 36.3194 17.5533 36.3526L23.852 38.0084C23.98 38.0417 24.0559 38.1721 24.0223 38.299C23.9887 38.4258 23.857 38.5009 23.7289 38.4676Z'
          fill='#4F4FD2'
        />
        <path
          d='M23.6656 26.1353C21.5494 25.5789 19.3774 26.8272 18.8154 28.9225C18.2534 31.0179 19.5141 33.1686 21.6303 33.725C23.7464 34.2815 25.9185 33.0332 26.4804 30.9378C27.0424 28.8425 25.7817 26.6918 23.6656 26.1353ZM22.0331 32.2219C20.755 31.8858 19.994 30.5882 20.3335 29.3227C20.6729 28.0571 21.9834 27.3037 23.2615 27.6397C24.5396 27.9758 25.3005 29.2734 24.9611 30.539C24.6217 31.8045 23.3112 32.558 22.0331 32.2219Z'
          fill='#4F4FD2'
        />
        <mask
          id='mask1_3544_56286'
          style={{ maskType: 'luminance' }}
          maskUnits='userSpaceOnUse'
          x='18'
          y='26'
          width='9'
          height='8'
        >
          <path
            d='M23.6656 26.1353C21.5494 25.5789 19.3774 26.8272 18.8154 28.9225C18.2534 31.0179 19.5141 33.1686 21.6303 33.725C23.7464 34.2815 25.9185 33.0332 26.4804 30.9378C27.0424 28.8425 25.7817 26.6918 23.6656 26.1353ZM22.0331 32.2219C20.755 31.8858 19.994 30.5882 20.3335 29.3227C20.6729 28.0571 21.9834 27.3037 23.2615 27.6397C24.5396 27.9758 25.3005 29.2734 24.9611 30.539C24.6217 31.8045 23.3112 32.558 22.0331 32.2219Z'
            fill='white'
          />
        </mask>
        <g mask='url(#mask1_3544_56286)'>
          <path
            d='M23.9082 25.2279L22.8962 29.0037L21.4278 29.7387L18.6465 27.0967C18.6465 27.0967 20.4779 24.8377 23.9082 25.2279Z'
            fill='#FFAAA0'
          />
          <path
            d='M19.1718 27.5957L21.4284 29.739L22.5772 31.6669L24.3228 34.4332C24.3228 34.4332 19.8655 35.8797 18.4382 32.2049C17.0121 28.5301 19.1718 27.5957 19.1718 27.5957Z'
            fill='#FF3F4F'
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_3544_56286'
          x1='12.9724'
          y1='36.8578'
          x2='21.8468'
          y2='3.10304'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#3535B3' />
          <stop offset='0.06' stopColor='#3535B3' stopOpacity='0.95' />
          <stop offset='0.15' stopColor='#3535B3' stopOpacity='0.81' />
          <stop offset='0.27' stopColor='#3535B3' stopOpacity='0.59' />
          <stop offset='0.41' stopColor='#3535B3' stopOpacity='0.28' />
          <stop offset='0.52' stopColor='#3535B3' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_3544_56286'
          x1='19.84'
          y1='3.73904'
          x2='11.5376'
          y2='35.3183'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFEBE7' />
          <stop offset='0.74' stopColor='#FFEBE7' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_3544_56286'
          x1='12.9634'
          y1='18.7844'
          x2='15.5322'
          y2='9.01377'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFEBE7' />
          <stop offset='0.74' stopColor='#FFEBE7' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_3544_56286'
          x1='19.6274'
          y1='40.6402'
          x2='24.2876'
          y2='22.9145'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#B4B4EC' />
          <stop offset='0.36' stopColor='#D0C9EA' stopOpacity='0.62' />
          <stop offset='0.8' stopColor='#F1E1E7' stopOpacity='0.18' />
          <stop offset='1' stopColor='#FFEBE7' stopOpacity='0' />
        </linearGradient>
      </defs>
    </>
  );
};
