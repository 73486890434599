import React from 'react';
import { LightBeamSmall } from './light-beam';
import { RainbowSmall } from './rainbow';
import { PrismTriangle } from './PrismTriangle';
import { Background } from './background';
import { type CaasProps } from './types';
import { BubbleIcons } from './bubbles';
import { animationsStyle, useScrollView } from './hooks/useScrollAnimation';
import { HoverIcons } from './hover-icons';

export const CaasSmall = ({ timeouts }: CaasProps) => {
  const { nodeRef, inView, rainbowCls, lightPrismCls, showText } = useScrollView();

  return (
    <svg
      ref={nodeRef}
      className={'relative left-[-5%] h-auto w-[150%]'}
      width='549px'
      height='339px'
      viewBox='0 0 549 339'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <style
        dangerouslySetInnerHTML={{
          __html: animationsStyle
        }}
      />
      <Background />
      <RainbowSmall />
      <PrismTriangle />
      <LightBeamSmall showText={showText} />
      <HoverIcons inView={inView} />
      <BubbleIcons inView={inView} collapsed timeouts={timeouts} />
      <defs>
        <mask id='rainbow-mask'>
          <rect width='0' height='100%' fill='#FFFFFF' className={rainbowCls} />
        </mask>
        <mask id='prism-mask'>
          <rect width='0' height='100%' fill='#FFFFFF' className={lightPrismCls} />
        </mask>
      </defs>
    </svg>
  );
};
