import React from 'react';
import { theme } from '@konsus/superside-kit';
import { textAnimationsStyle, useText } from '../hooks/useText';
import { glowStyle } from '../commonStyles';
import { type LightBeamProps } from '../types';

export const LightBeamSmall = ({ showText }: LightBeamProps) => {
  const { textCls } = useText(showText);

  return (
    <>
      <style dangerouslySetInnerHTML={{ __html: textAnimationsStyle }} />
      <g className={glowStyle}>
        <path
          d='M8.00294 202C7.05804 202 6.21682 201.329 6.03453 200.366C5.82798 199.277 6.54377 198.227 7.63167 198.021L160.62 169.035C161.709 168.828 162.758 169.545 162.963 170.633C163.17 171.722 162.454 172.772 161.366 172.978L8.37923 201.965C8.25297 201.989 8.12754 202 8.00378 202H8.00294Z'
          fill='url(#paint15_linear_38_51)'
          mask='url(#prism-mask)'
        />
        <path
          d='M21.4845 198.94C20.803 198.94 20.1959 198.455 20.0646 197.761C19.9158 196.975 20.4317 196.218 21.2169 196.069L161.283 169.429C162.069 169.28 162.826 169.796 162.974 170.582C163.123 171.368 162.607 172.125 161.822 172.274L21.7554 198.915C21.6643 198.932 21.574 198.941 21.4845 198.941V198.94Z'
          fill='url(#paint16_linear_38_51)'
          mask='url(#prism-mask)'
        />
        <path
          d='M162.447 170.601L8.00604 199.993'
          stroke='url(#paint17_linear_38_51)'
          strokeLinecap='round'
          strokeLinejoin='round'
          mask='url(#prism-mask)'
        />
      </g>

      <text
        mask='url(#text-mask)'
        style={{
          fill: theme.colors['alabaster-white'],
          fontFamily: 'Raleway',
          fontSize: '11px',
          fontWeight: 600,
          letterSpacing: '0.15em',
          textTransform: 'uppercase'
        }}
      >
        <tspan x='50' y='126'>
          your creative
        </tspan>
        <tspan x='82' y='143'>
          needs
        </tspan>
      </text>

      <path
        d='M101.854 155C101.854 155.736 102.451 156.333 103.188 156.333C103.924 156.333 104.521 155.736 104.521 155C104.521 154.264 103.924 153.667 103.188 153.667C102.451 153.667 101.854 154.264 101.854 155ZM102.938 155V182H103.438V155L102.938 155Z'
        fill='white'
        mask='url(#prism-mask)'
      />
      <defs>
        <mask id='text-mask'>
          <rect width='0' height='100%' fill='#FFFFFF' className={textCls} />
        </mask>

        <linearGradient
          id='paint15_linear_38_51'
          x1='5.99941'
          y1='185.5'
          x2='162.999'
          y2='185.5'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#006DAA' stopOpacity='0' />
          <stop offset='1' stopColor='#3C6DAA' />
        </linearGradient>
        <linearGradient
          id='paint16_linear_38_51'
          x1='20.0387'
          y1='184.171'
          x2='163'
          y2='184.171'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#6F9ED1' stopOpacity='0' />
          <stop offset='0.99' stopColor='#6F9ED1' />
        </linearGradient>
        <linearGradient
          id='paint17_linear_38_51'
          x1='7.70501'
          y1='185.297'
          x2='162.748'
          y2='185.297'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.09' stopColor='white' stopOpacity='0' />
          <stop offset='0.39' stopColor='white' />
          <stop offset='1' stopColor='white' />
        </linearGradient>
      </defs>
    </>
  );
};
