import React from 'react';
import { Flares } from './Flares';
import { Clouds } from './Clouds';
import { Stars } from './Stars';

export const Background = () => {
  return (
    (<g className={"768:scale-100 scale-[0.4] translate-x-[50px] translate-y-5"}>
      <Flares />
      <Stars />
      <Clouds />
    </g>)
  );
};
