import React, { useState } from 'react';
import { cn } from '@superside/ui';
import { type TextType } from '../types';

export interface HoverIconProps {
  id: number;
  text1: TextType;
  text2: TextType;
  pathData: string;
  cx: string;
  cy: string;
  filterX: string;
  filterY: string;
  x1: string;
  y1: string;
  x2: string;
  y2: string;
  glowX: string;
  glowY: string;
  mobileTranslation?: string;
  glowIndex?: number;
}

const HOVER_SIZE = 42;

export const HoverIcon = ({
  id,
  text1,
  text2,
  pathData,
  cx,
  cy,
  filterX,
  filterY,
  x1,
  y1,
  x2,
  y2,
  glowX,
  glowY,
  mobileTranslation,
  glowIndex = undefined
}: HoverIconProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const isActiveGlow = glowIndex === id;

  return (
    <g
      style={
        {
          '--mobile-translation-var-4628': `translate3d(${mobileTranslation})`
        } as React.CSSProperties
      }
      className={
        '[&_text,stop,.hoverIconFilter]:duration-[0.3s] [&_text,stop,.hoverIconFilter]:ease-[ease-in-out] [&_text,stop,.hoverIconFilter]:transition-all'
      }
    >
      <text
        className={cn(
          `fill-black-100/0 tracking-15 text-center font-sans font-semibold uppercase not-italic leading-[150%] transition-all duration-[0.3s] ease-[ease-in-out]`,
          isHovered && 'fill-light'
        )}
      >
        <tspan x={text1.x} y={text1.y}>
          {text1.text}
        </tspan>
        <tspan x={text2.x} y={text2.y}>
          {text2.text}
        </tspan>
      </text>
      <g filter={`url(#hover_icon_filter_${id})`}>
        <path d={pathData} fill={`url(#path_fill_${id})`} />
      </g>
      <g
        opacity={isHovered ? '1' : '0'}
        className='hoverIconFilter'
        style={{ mixBlendMode: 'overlay' }}
        filter={`url(#glow_filter_${id})`}
      >
        <circle cx={cx} cy={cy} r='31.5' fill='white' />
      </g>
      <rect
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        x={filterX}
        y={filterY}
        width={HOVER_SIZE}
        height={HOVER_SIZE}
        fill='transparent'
        cursor='pointer'
      />
      <defs>
        <filter
          id={`hover_icon_filter_${id}`}
          x={filterX}
          y={filterY}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='1' />
          <feGaussianBlur stdDeviation='0.5' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.0886111 0 0 0 0 0.229274 0 0 0 0 0.366667 0 0 0 1 0'
          />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_38_51' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_38_51' result='shape' />
        </filter>

        <linearGradient
          id={`path_fill_${id}`}
          x1={x1}
          y1={y1}
          x2={x2}
          y2={y2}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor={isHovered || isActiveGlow ? '#7BF2FA' : '#48A4E1'} />
          <stop offset='1' stopColor={isHovered || isActiveGlow ? '#619acf' : '#357BBC'} />
        </linearGradient>

        <filter
          id={`glow_filter_${id}`}
          x={glowX}
          y={glowY}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feGaussianBlur stdDeviation='25' result='effect1_foregroundBlur_38_51' />
        </filter>
      </defs>
    </g>
  );
};
