import React from 'react';

export const Motion = ({ className }) => {
  return (
    <>
      <g className={className}>
        <path
          opacity='0.5'
          d='M22.6247 38.7467C33.2551 37.0212 40.4739 27.0047 38.7483 16.3743C37.0228 5.74385 27.0063 -1.47496 16.3759 0.250598C5.74546 1.97616 -1.47335 11.9927 0.25221 22.6231C1.97777 33.2535 11.9943 40.4723 22.6247 38.7467Z'
          fill='#FF5457'
        />
        <path
          d='M36.4319 21.1796C37.3601 11.7973 30.5067 3.43892 21.1244 2.51071C11.7421 1.5825 3.38379 8.43591 2.45558 17.8182C1.52737 27.2005 8.38077 35.5589 17.7631 36.4871C27.1454 37.4153 35.5037 30.5619 36.4319 21.1796Z'
          fill='#FF5457'
        />
        <path
          d='M15.7729 13.7594C15.7729 13.7594 14.672 18.267 15.7729 25.2389C15.6685 25.291 22.7969 23.6668 26.6246 19.4982C26.7289 19.3678 21.6979 15.121 15.7729 13.7576V13.7594Z'
          fill='white'
        />
      </g>
    </>
  );
};
