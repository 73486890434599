import { type BubbleProps } from './Bubble';
import {
  DigitalAds,
  LandingPage,
  Video,
  BrandIdentity,
  ConceptCreation,
  PrintDesign,
  Ebooks,
  Illustration,
  Mail,
  Motion
} from './icons';

type BubbleListType = Omit<BubbleProps, 'hoverBubble'>[];

export const bubblesFirstList: BubbleListType = [
  {
    id: 0,
    x: 580,
    y: 160,
    href: '/video-production',
    text1: { x: '50', y: '95', text: 'Video' },
    Icon: Video,
    bubbleScale: 1.1,
    iconStyles: {
      normal: '45px, 35px',
      normalScale: '1.5',
      hover: '40px, 35px',
      hoverScale: '1.7',
      otherHovered: '45px, 35px',
      otherHoveredScale: '1.5',
      normalDesktop: '45px, 35px',
      normalDesktopScale: '1.3',
      hoverDesktop: '45px, 30px',
      hoverDesktopScale: '1.5',
      otherHoveredDesktop: '45px, 40px',
      otherHoveredDesktopScale: '1.3'
    }
  },
  {
    id: 1,
    x: 675,
    y: 250,
    href: '/landing-page-design',
    text1: { x: '35', y: '78', text: 'Landing' },
    text2: { x: '50', y: '90', text: 'Page' },
    Icon: LandingPage,
    iconStyles: {
      normal: '35px, 35px',
      normalScale: '1.5',
      hover: '30px, 35px',
      hoverScale: '1.7',
      otherHovered: '35px, 35px',
      otherHoveredScale: '1.5',
      normalDesktop: '40px, 30px',
      normalDesktopScale: '1.3',
      hoverDesktop: '35px, 25px',
      hoverDesktopScale: '1.5',
      otherHoveredDesktop: '35px, 40px',
      otherHoveredDesktopScale: '1.3'
    }
  },
  {
    id: 2,
    x: 680,
    y: 370,
    href: '/branding-services',
    text1: { x: '45', y: '85', text: 'Brand' },
    text2: { x: '40', y: '100', text: 'Identity' },
    Icon: BrandIdentity,
    bubbleScale: 1.1,
    iconStyles: {
      normal: '35px, 40px',
      normalScale: '1.5',
      hover: '30px, 35px',
      hoverScale: '1.7',
      otherHovered: '35px, 35px',
      otherHoveredScale: '1.5',
      normalDesktop: '40px, 30px',
      normalDesktopScale: '1.3',
      hoverDesktop: '35px, 25px',
      hoverDesktopScale: '1.5',
      otherHoveredDesktop: '40px, 40px',
      otherHoveredDesktopScale: '1.5'
    }
  },
  {
    id: 3,
    x: 775,
    y: 310,
    href: '/motion-design',
    text1: { x: '32', y: '80', text: 'Motion' },
    Icon: Motion,
    bubbleScale: 0.95,
    iconStyles: {
      normal: '32px, 32px',
      normalScale: '1.3',
      hover: '32px, 32px',
      hoverScale: '1.4',
      otherHovered: '35px, 35px',
      otherHoveredScale: '1.2',
      normalDesktop: '40px, 25px',
      normalDesktopScale: '1',
      hoverDesktop: '40px, 25px',
      hoverDesktopScale: '1.1',
      otherHoveredDesktop: '35px, 30px',
      otherHoveredDesktopScale: '1.2'
    }
  },
  {
    id: 4,
    x: 800,
    y: 420,
    href: '/ad-creative',
    text1: { x: '30', y: '77', text: 'Digital' },
    text2: { x: '45', y: '90', text: 'Ads' },
    Icon: DigitalAds,
    iconStyles: {
      normal: '35px, 35px',
      normalScale: '1.3',
      hover: '30px, 35px',
      hoverScale: '1.4',
      otherHovered: '35px, 35px',
      otherHoveredScale: '1.2',
      normalDesktop: '40px, 25px',
      normalDesktopScale: '1',
      hoverDesktop: '40px, 25px',
      hoverDesktopScale: '1.1',
      otherHoveredDesktop: '35px, 35px',
      otherHoveredDesktopScale: '1.3'
    }
  }
];

export const bubblesSecondList: BubbleListType = [
  {
    id: 0,
    x: 580,
    y: 160,
    href: '/ebook-digital-report-design',
    text1: { x: '30', y: '80', text: 'E-books' },
    Icon: Ebooks,
    bubbleScale: 0.95,
    iconStyles: {
      normal: '40px, 35px',
      normalScale: '1.3',
      hover: '40px, 30px',
      hoverScale: '1.4',
      otherHovered: '40px, 35px',
      otherHoveredScale: '1.2',
      normalDesktop: '45px, 25px',
      normalDesktopScale: '1',
      hoverDesktop: '45px, 25px',
      hoverDesktopScale: '1.1',
      otherHoveredDesktop: '40px, 30px',
      otherHoveredDesktopScale: '1.2'
    }
  },
  {
    id: 1,
    x: 660,
    y: 230,
    href: '/illustration-design',
    text1: { x: '50', y: '90', text: 'Illus-' },
    text2: { x: '45', y: '105', text: 'tration' },
    Icon: Illustration,
    bubbleScale: 1.15,
    iconStyles: {
      normal: '45px, 35px',
      normalScale: '1.3',
      hover: '45px, 30px',
      hoverScale: '1.4',
      otherHovered: '45px, 35px',
      otherHoveredScale: '1.2',
      normalDesktop: '55px, 25px',
      normalDesktopScale: '1',
      hoverDesktop: '50px, 25px',
      hoverDesktopScale: '1.1',
      otherHoveredDesktop: '50px, 35px',
      otherHoveredDesktopScale: '1.2'
    }
  },
  {
    id: 2,
    x: 680,
    y: 360,
    href: '/email-design',
    text1: { x: '43', y: '80', text: 'Email' },
    text2: { x: '38', y: '95', text: 'design' },
    Icon: Mail,
    iconStyles: {
      normal: '42px, 32px',
      normalScale: '1.3',
      hover: '40px, 30px',
      hoverScale: '1.4',
      otherHovered: '42px, 35px',
      otherHoveredScale: '1.2',
      normalDesktop: '45px, 25px',
      normalDesktopScale: '1',
      hoverDesktop: '42px, 22px',
      hoverDesktopScale: '1.1',
      otherHoveredDesktop: '42px, 32px',
      otherHoveredDesktopScale: '1.2'
    }
  },
  {
    id: 3,
    x: 775,
    y: 305,
    href: '/print-design',
    text1: { x: '40', y: '72', text: 'Print' },
    text2: { x: '35', y: '85', text: 'design' },
    Icon: PrintDesign,
    bubbleScale: 0.95,
    iconStyles: {
      normal: '30px, 30px',
      normalScale: '1.3',
      hover: '30px, 30px',
      hoverScale: '1.4',
      otherHovered: '30px, 35px',
      otherHoveredScale: '1.2',
      normalDesktop: '35px, 25px',
      normalDesktopScale: '1',
      hoverDesktop: '35px, 20px',
      hoverDesktopScale: '1.1',
      otherHoveredDesktop: '30px, 30px',
      otherHoveredDesktopScale: '1.2'
    }
  },
  {
    id: 4,
    x: 780,
    y: 420,
    href: '/concept-creation',
    text1: { x: '42', y: '90', text: 'Concept' },
    text2: { x: '40', y: '105', text: 'Creation' },
    Icon: ConceptCreation,
    bubbleScale: 1.15,
    iconStyles: {
      normal: '40px, 35px',
      normalScale: '1.5',
      hover: '40px, 35px',
      hoverScale: '1.4',
      otherHovered: '42px, 35px',
      otherHoveredScale: '1.5',
      normalDesktop: '50px, 30px',
      normalDesktopScale: '1',
      hoverDesktop: '50px, 30px',
      hoverDesktopScale: '1.1',
      otherHoveredDesktop: '50px, 40px',
      otherHoveredDesktopScale: '1.2'
    }
  }
];
