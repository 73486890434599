import React from 'react';

export function useText(showText: boolean) {
  const [textCls, setTextCls] = React.useState('');

  React.useEffect(() => {
    if (showText) {
      setTextCls('fade-in');
    } else {
      setTextCls('');
    }
  }, [showText]);

  return { textCls };
}

export const textAnimationsStyle = `
	@keyframes fadeIn {
		from {
			opacity: 0;
		}

		to {
		opacity: 1;
		width: 100%;
		}
	}

	.fade-in {
		animation: 1s fadeIn 1.5s ease-in forwards;
	}
`;
