import React from 'react';

export const Clouds = () => {
  return (
    <>
      <g className='animation-cloud-move origin-center'>
        <path
          d='M227 240.51C227 240.51 316.499 237.868 346.547 240.51C376.596 243.152 378.557 234.346 377.906 231.354C377.256 228.362 374.75 222.815 369.415 224.134C364.079 225.453 356.024 231.001 356.024 231.001C356.024 231.001 359.724 218.323 354.718 217.177C349.708 216.031 331.962 230.561 327.826 230.384C323.689 230.208 324.67 223.341 319.985 222.724C315.304 222.108 311.493 227.565 306.264 228.095C301.04 228.625 293.636 225.103 289.713 225.453C285.791 225.803 285.795 229.061 284.378 228.888C282.853 228.802 275.342 226.599 270.876 226.686C264.234 227.126 260.748 237.781 226.996 240.51H227Z'
          fill='#F9F9F9'
        />
        <path
          opacity='0.5'
          d='M227 240.51C227 240.51 316.499 237.868 346.547 240.51C376.596 243.152 378.557 234.346 377.906 231.354C377.256 228.362 374.75 222.815 369.415 224.134C364.079 225.453 356.024 231.001 356.024 231.001C356.024 231.001 359.724 218.323 354.718 217.177C349.708 216.031 331.962 230.561 327.826 230.384C323.689 230.208 324.67 223.341 319.985 222.724C315.304 222.108 311.493 227.565 306.264 228.095C301.04 228.625 293.636 225.103 289.713 225.453C285.791 225.803 285.795 229.061 284.378 228.888C282.853 228.802 275.342 226.599 270.876 226.686C264.234 227.126 260.748 237.781 226.996 240.51H227Z'
          fill='url(#paint2_linear_38_451)'
        />
        <path
          d='M369.049 199.601C370.137 199.601 371.336 199.601 372.098 199.161C372.753 198.808 372.86 198.281 372.967 197.751C373.293 196.255 373.404 194.669 373.293 193.083C373.186 192.027 372.856 190.881 372.094 189.915C370.676 188.066 367.413 186.833 364.146 186.746C360.772 186.57 357.504 187.539 355.329 189.035C355.003 189.212 354.567 189.475 354.13 189.298C354.023 189.212 353.911 189.212 353.911 189.122C352.28 187.273 350.862 185.337 349.882 183.311C349.012 181.902 348.25 180.229 346.07 179.436C343.891 178.643 340.953 178.996 338.992 179.966C337.031 180.936 335.724 182.255 334.53 183.664C332.787 185.777 331.155 188.066 329.849 190.268C329.742 188.949 329.306 187.539 328.65 186.216C328.432 185.863 328.213 185.423 327.781 185.07C327.019 184.54 325.931 184.367 324.844 184.454C321.795 184.54 319.182 185.95 317.44 187.536C315.697 189.122 314.609 190.971 312.759 192.467C312.434 192.73 311.997 192.997 311.56 193.17C310.691 193.346 309.71 193.17 308.836 192.907C305.569 191.937 301.651 190.881 298.276 191.851C296.315 192.38 295.009 193.437 293.703 194.493C291.091 196.428 288.149 198.368 284.231 199.071C285.648 199.071 287.168 199.157 288.586 199.157C288.586 199.157 342.808 199.334 369.049 199.597V199.601Z'
          fill='url(#paint3_linear_38_451)'
        />
      </g>
      <defs>
        <linearGradient
          id='paint2_linear_38_451'
          x1='302.502'
          y1='217.18'
          x2='302.502'
          y2='241.007'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.59' stopColor='white' />
          <stop offset='0.86' stopColor='#7798F7' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_38_451'
          x1='328.784'
          y1='179'
          x2='328.784'
          y2='199.55'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.51' stopColor='white' />
          <stop offset='0.59' stopColor='#FAFBFF' />
          <stop offset='0.67' stopColor='#EEF2FD' />
          <stop offset='0.75' stopColor='#DAE4FC' />
          <stop offset='0.84' stopColor='#BECEFA' />
          <stop offset='0.92' stopColor='#9BB4F8' />
          <stop offset='1' stopColor='#7798F7' />
        </linearGradient>
      </defs>
    </>
  );
};
