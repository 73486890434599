import React from 'react';

const stars = [
  {
    d: 'M904.756 587.175C903.199 587.175 903.199 585 904.756 585C906.313 585 906.313 587.175 904.756 587.175Z',
    fill: '#F3B5AA'
  },
  {
    d: 'M750.234 391.59C748.677 391.59 748.677 389.414 750.234 389.414C751.791 389.414 751.791 391.59 750.234 391.59Z',
    fill: '#F3B5AA'
  },
  {
    d: 'M101.244 685C99.6869 685 99.6869 682.824 101.244 682.824C102.801 682.824 102.801 685 101.244 685Z',
    fill: '#F3B5AA'
  },
  {
    d: 'M255.766 235.122C254.209 235.122 254.209 232.946 255.766 232.946C257.323 232.946 257.323 235.122 255.766 235.122Z',
    fill: '#F3B5AA'
  },
  {
    d: 'M410.288 489.415C408.731 489.415 408.731 487.239 410.288 487.239C411.845 487.175 411.845 489.415 410.288 489.415Z',
    fill: '#F3B5AA'
  },
  {
    d: 'M533.904 430.707C532.347 430.707 532.347 428.532 533.904 428.532C535.461 428.532 535.461 430.707 533.904 430.707Z',
    fill: '#F3B5AA'
  },
  {
    d: 'M472.096 156.888C470.539 156.888 470.539 154.712 472.096 154.712C473.653 154.712 473.653 156.888 472.096 156.888Z',
    fill: '#F3B5AA'
  },
  {
    d: 'M503.003 548.059C501.446 548.059 501.446 545.883 503.003 545.883C504.56 545.883 504.56 548.059 503.003 548.059Z',
    fill: '#F3B5AA'
  },
  {
    d: 'M564.81 332.946C563.253 332.946 563.253 330.771 564.81 330.771C566.367 330.707 566.367 332.946 564.81 332.946Z',
    fill: '#F3B5AA'
  },
  {
    d: 'M842.948 215.531C841.391 215.531 841.391 213.356 842.948 213.356C844.505 213.356 844.505 215.531 842.948 215.531Z',
    fill: '#F3B5AA'
  },
  {
    d: 'M348.48 98.1756C346.923 98.1756 346.923 96 348.48 96C350.037 96 350.037 98.1756 348.48 98.1756Z',
    fill: '#F3B5AA'
  },
  {
    d: 'M688.426 645.114C687.932 645.114 687.932 644.472 688.426 644.472C688.92 644.472 688.92 645.114 688.426 645.114Z',
    fill: 'white'
  },
  {
    d: 'M626.618 527.763C626.124 527.763 626.124 527.121 626.618 527.121C627.112 527.121 627.112 527.763 626.618 527.763Z',
    fill: 'white'
  },
  {
    d: 'M812.047 508.172C811.553 508.172 811.553 507.53 812.047 507.53C812.541 507.53 812.541 508.172 812.047 508.172Z',
    fill: 'white'
  },
  {
    d: 'M657.525 175.645C657.031 175.645 657.031 175.004 657.525 175.004C658.018 175.004 658.018 175.645 657.525 175.645Z',
    fill: 'white'
  },
  {
    d: 'M317.574 625.523C317.08 625.523 317.08 624.881 317.574 624.881C318.068 624.881 318.068 625.523 317.574 625.523Z',
    fill: 'white'
  },
  {
    d: 'M286.673 449.528C286.179 449.528 286.179 448.886 286.673 448.886C287.167 448.823 287.167 449.528 286.673 449.528Z',
    fill: 'white'
  },
  {
    d: 'M224.86 195.236C224.366 195.236 224.366 194.594 224.86 194.594C225.353 194.594 225.353 195.236 224.86 195.236Z',
    fill: 'white'
  },
  {
    d: 'M595.712 371.294C595.218 371.294 595.218 370.652 595.712 370.652C596.205 370.589 596.205 371.294 595.712 371.294Z',
    fill: 'white'
  },
  {
    d: 'M781.14 566.88C780.647 566.88 780.647 566.238 781.14 566.238C781.634 566.238 781.634 566.88 781.14 566.88Z',
    fill: '#4F4FC7'
  },
  {
    d: 'M873.855 352.728C871.94 352.728 871.94 350.102 873.855 350.102C875.77 350.038 875.77 352.728 873.855 352.728Z',
    fill: '#4F4FC7'
  },
  {
    d: 'M193.959 411.372C192.044 411.372 192.044 408.745 193.959 408.745C195.874 408.745 195.803 411.372 193.959 411.372Z',
    fill: '#4F4FC7'
  },
  {
    d: 'M379.382 313.611C377.467 313.611 377.467 310.985 379.382 310.985C381.226 310.921 381.297 313.611 379.382 313.611Z',
    fill: '#4F4FC7'
  },
  {
    d: 'M719.333 606.957C717.418 606.957 717.418 604.331 719.333 604.331C721.248 604.331 721.248 606.957 719.333 606.957Z',
    fill: '#4F4FC7'
  },
  {
    d: 'M441.195 274.43C439.28 274.43 439.28 271.804 441.195 271.804C443.11 271.804 443.11 274.43 441.195 274.43Z',
    fill: '#4F4FC7'
  },
  {
    d: 'M39.4363 294.021C37.5212 294.021 37.5212 291.394 39.4363 291.394C41.2808 291.394 41.2808 294.021 39.4363 294.021Z',
    fill: '#4F4FC7'
  },
  {
    d: 'M163.052 117.962C161.137 117.962 161.137 115.336 163.052 115.336C164.897 115.336 164.897 117.962 163.052 117.962Z',
    fill: '#4F4FC7'
  },
  {
    d: 'M132.151 254.904C130.236 254.904 130.236 252.277 132.151 252.277C133.995 252.277 133.995 254.904 132.151 254.904Z',
    fill: '#4F4FC7'
  },
  {
    d: 'M966.564 665.669C964.649 665.669 964.649 663.043 966.564 663.043C968.479 663.043 968.479 665.669 966.564 665.669Z',
    fill: '#4F4FC7'
  },
  {
    d: 'M935.891 469.29C937.703 468.625 939.077 467.872 938.958 467.609C938.84 467.346 937.275 467.672 935.462 468.337C933.65 469.003 932.276 469.755 932.395 470.019C932.513 470.282 934.078 469.956 935.891 469.29Z',
    fill: 'white'
  },
  {
    d: 'M937.011 471.778C937.302 471.671 936.941 470.257 936.204 468.62C935.468 466.983 934.634 465.743 934.343 465.85C934.051 465.957 934.412 467.371 935.149 469.008C935.886 470.645 936.719 471.885 937.011 471.778Z',
    fill: 'white'
  }
];

export const Stars = () => {
  return (
    (<g>
      {stars.map((star, index) => (
        <path
          key={index}
          d={star.d}
          fill={star.fill}
          style={{ ["--twinkle-duration-3fc0"]: `${Math.floor(Math.random() * 2000) + 1000}ms` }}
          className={""}
        />
      ))}
    </g>)
  );
};
