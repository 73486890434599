import React from 'react';

export const BrandIdentity = ({ className }) => {
  return (
    <>
      <g className={className}>
        <path
          d='M19.0725 25.7363L22.6822 25.1992L22.7916 25.8897C22.8868 26.4906 22.4607 27.0534 21.8405 27.1457L20.4781 27.3484C19.8579 27.4407 19.2772 27.0278 19.1819 26.4268L19.0725 25.7363Z'
          fill='#4F4FCF'
        />
        <path
          d='M21.0285 26.6781C15.3654 26.6525 9.80991 28.0991 4.38128 29.6653C3.77497 29.8412 3.20743 29.5047 3.11225 28.9001C1.89433 21.2127 1.23162 17.0316 0.0136995 9.34248C-0.0814778 8.73958 0.329195 8.09739 0.937272 7.92147C6.3659 6.35529 12.0254 5.56964 17.6885 5.59355C19.021 14.0103 19.696 18.2614 21.0285 26.6764V26.6781Z'
          fill='#4F4FCF'
        />
        <path
          d='M37.8502 24.6885C32.1819 24.7705 26.4289 25.0062 21.0285 26.6783C19.6942 18.2615 19.021 14.0122 17.6885 5.59542C23.0889 3.92334 28.7361 3.02838 34.4062 2.9464C35.0301 2.93786 35.6135 3.42292 35.7069 4.01728C36.9266 11.715 37.5911 15.908 38.8108 23.6057C38.9042 24.2 38.4741 24.6783 37.8502 24.6885Z'
          fill='#4F4FCF'
        />
        <path
          d='M20.9316 26.1692C15.3637 26.1436 9.14897 27.2726 3.84548 28.9156C2.52886 20.6133 1.7692 15.8157 0.454346 7.5151C5.75784 5.87376 12.0589 5.29477 17.6268 5.32039C18.9417 13.6227 19.6149 17.8686 20.9298 26.1692H20.9316Z'
          fill='#FF5457'
        />
        <path
          d='M20.9315 26.1693C26.235 24.5262 32.4938 23.8004 38.0617 23.826C36.7468 15.5237 35.9872 10.7278 34.6705 2.42546C29.1027 2.40155 23.1259 4.90198 17.8224 6.54331C19.1372 14.8456 19.6167 17.867 20.9315 26.1693Z'
          fill='#FF5457'
        />
        <path
          d='M17.4543 4.23413L20.9283 26.1675C19.8884 25.9814 18.8644 25.8703 17.8509 25.8259C13.1767 25.6073 8.76854 26.7311 4.41506 28.0787L1.02393 6.6782C5.46377 5.30501 10.0887 4.49886 14.7418 4.2922H14.7471C15.6248 4.24779 16.5061 4.23071 17.3874 4.23584H17.3997C17.4173 4.23584 17.435 4.23242 17.4543 4.23584V4.23413Z'
          fill='#FFB3A8'
        />
        <path
          d='M33.8881 1.78833L37.2774 23.1871C31.9739 23.1632 26.4395 23.5492 21.6472 25.8105C21.404 25.925 21.1643 26.0445 20.9281 26.1675L17.4541 4.2341C17.4682 4.2324 17.4753 4.22727 17.4894 4.22386H17.4982C17.4982 4.22386 17.5017 4.21702 17.507 4.21702C17.6586 4.17262 17.8031 4.12479 17.9547 4.08551C23.1083 2.53982 28.4964 1.76442 33.8881 1.79004V1.78833Z'
          fill='#FFB3A8'
        />
        <path
          d='M27.3866 11.1545C26.3961 9.46193 25.3526 7.65151 24.3639 5.88037L23.8457 6.05458L22.3704 11.9367C22.2136 12.5567 22.0866 12.8249 21.8328 12.9888C21.6954 13.0811 21.5173 13.1357 21.2759 13.2006L21.3358 13.5986L21.498 13.5747L22.7247 13.4056L24.0801 13.2143L24.0202 12.8163C23.1318 12.8624 22.8516 12.8163 22.982 12.1058C23.012 11.9436 23.0455 11.7642 23.0842 11.5832C23.123 11.3851 23.1688 11.1852 23.2023 11.0042C23.2041 10.9939 23.2076 10.9854 23.2094 10.9752L24.6441 10.7719L25.372 10.6711C25.6064 11.0759 25.855 11.5354 26.0154 11.8445C26.2339 12.2698 26.2533 12.3945 25.6346 12.5806C25.6135 12.584 25.5923 12.5909 25.5712 12.5977L25.6311 12.9957L25.6928 12.9871L27.6792 12.7053L28.8513 12.5413L28.7914 12.1434C28.0564 12.1605 27.9436 12.0973 27.3849 11.1562L27.3866 11.1545ZM24.3568 10.251L23.3187 10.3979C23.4138 9.90428 23.5249 9.43801 23.6447 8.96321C23.724 8.64382 23.8051 8.32102 23.8897 7.99139L25.083 10.1502L24.3586 10.251H24.3568Z'
          fill='#FF5457'
        />
        <path
          d='M22.4227 18.5962L27.9624 17.7712'
          stroke='#FF5457'
          strokeWidth='0.625'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M22.6627 20.1077L28.2024 19.2844'
          stroke='#FF5457'
          strokeWidth='0.625'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8.3689 16.5689L13.4239 15.8174'
          stroke='#FF5457'
          strokeWidth='0.625'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8.58716 17.9487L11.8214 17.467'
          stroke='#FF5457'
          strokeWidth='0.625'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M22.9025 21.6208L25.9693 21.1648'
          stroke='#FF5457'
          strokeWidth='0.625'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M15.5007 7.36993L3.97827 9.08447L4.89826 14.89L16.4207 13.1755L15.5007 7.36993Z'
          stroke='#FF5457'
          strokeWidth='0.625'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <g opacity='0.4'>
          <path
            d='M14.9 4.27676C15.7778 4.23406 16.659 4.21698 17.5403 4.22039L14.8278 4.58931V4.58419L14.8965 4.27676H14.9018H14.9Z'
            fill='#4F4FCF'
          />
          <path
            d='M21.6907 24.9462C21.6995 25.0026 21.7489 25.3698 21.8035 25.7951C21.5603 25.9095 21.3206 26.0291 21.0844 26.1521C20.0445 25.9659 19.0204 25.8549 18.007 25.8105L17.9612 25.5457V25.5372C17.9576 25.486 17.9841 25.4296 18.0299 25.3784L17.6051 22.6081L14.8326 4.59099L17.5452 4.22208H17.5575C17.5752 4.22208 17.5928 4.21866 17.6122 4.22208C17.6263 4.22037 17.6333 4.21525 17.6474 4.21183H17.6562C17.6562 4.21183 17.6598 4.205 17.665 4.205C17.8166 4.16059 17.9612 4.11277 18.1127 4.07349L18.1515 4.1418L20.0039 15.8053L21.5021 24.7532C21.6131 24.7977 21.6819 24.8626 21.696 24.948L21.6907 24.9462Z'
            fill='#4F4FCF'
          />
        </g>
        <path
          d='M21.3281 27.4722L21.0707 25.8992L19.3664 15.7164L17.4893 3.89917L14.0505 4.36544L16.8847 22.7651L17.4346 26.374L17.669 27.9538L21.3316 27.4705L21.3281 27.4722Z'
          fill='#4F4FCF'
        />
        <path
          d='M14.7137 1.41256L15.9898 1.24006L15.3148 0.0786553C15.2601 -0.0357769 15.0874 -0.0204054 15.0557 0.102566L14.7137 1.41256Z'
          fill='#4F4FCF'
        />
        <path
          d='M18.5995 26.3879L15.1309 4.26147'
          stroke='#FF5457'
          strokeWidth='1.5875'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M16.231 3.87549L19.823 26.0839'
          stroke='#FF5457'
          strokeWidth='1.5875'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M14.0507 4.36552L14.049 4.35869L14.7134 1.41249L15.9895 1.23828L17.4894 3.89925L14.0507 4.36552Z'
          fill='#7799FF'
        />
        <path
          opacity='0.4'
          d='M16.8831 22.7668L14.049 4.36715L17.4877 3.90088L19.3648 15.7181C18.3566 16.7309 18.2702 17.7198 18.0376 19.0862C17.8684 20.087 17.5194 21.9316 16.8814 22.7668H16.8831Z'
          fill='#7799FF'
        />
        <path
          d='M17.6408 28.094L17.2477 25.8088V25.802C17.216 25.4911 18.0373 25.1854 18.9345 25.0163C19.9515 24.8233 21.0671 24.8148 21.1147 25.1871C21.1359 25.3374 21.4267 27.4945 21.4302 27.5133C21.3756 27.5201 20.6882 27.609 19.9127 27.7148C18.9027 27.8498 17.7536 27.9984 17.6813 28.0069L17.639 28.094H17.6408Z'
          fill='#7799FF'
        />
        <path
          opacity='0.4'
          d='M17.6411 28.0292L17.2586 25.8003V25.7935C17.2269 25.4826 18.0482 25.1769 18.9453 25.0078C19.0158 25.1308 19.1057 25.2606 19.2221 25.3955C19.6539 25.8977 19.9923 26.4886 19.9852 27.1871C18.896 27.2777 17.597 27.638 17.6411 28.0292Z'
          fill='#4F4FCF'
        />
        <path
          d='M17.6798 28.1656C17.6604 28.1434 17.6516 28.1195 17.6428 28.0939L17.6463 28.0905C17.641 28.0768 17.634 28.0614 17.6357 28.0478L17.6322 28.0375C17.5881 27.6464 18.8889 27.286 19.9764 27.1955C20.7378 27.1357 21.404 27.2075 21.4305 27.4961C21.4375 28.2476 18.0358 28.6882 17.6798 28.1656Z'
          fill='#4F4FCF'
        />
        <path
          d='M21.4688 29.2262C21.319 28.666 18.1799 29.1818 18.1464 29.7403C18.1411 29.7266 18.1411 29.713 18.1376 29.701L17.8732 28.181L17.8697 28.1775C17.8697 28.1639 17.8591 28.1348 17.8591 28.1143C17.8838 27.4961 21.1322 27.0196 21.2185 27.6122C21.2115 27.6703 21.3983 28.7702 21.4547 29.1357C21.4582 29.1459 21.4635 29.1528 21.46 29.163C21.4618 29.1869 21.4635 29.204 21.4671 29.216V29.2228C21.4688 29.233 21.453 29.2365 21.4565 29.2467L21.4671 29.2228L21.4688 29.2262Z'
          fill='#FFB3A8'
        />
        <path
          d='M18.1804 29.807C18.1539 29.778 18.1416 29.737 18.1381 29.6994C18.0711 29.1239 21.4129 28.5773 21.4728 29.1905C21.4781 29.8412 18.4941 30.2648 18.1804 29.807Z'
          fill='#FF5457'
        />
        <path
          d='M5.11523 14.8574L7.56693 10.0479L11.4075 13.9334'
          stroke='#FF5457'
          strokeWidth='0.625'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M9.58301 12.0871L10.8044 10.4749L13.8501 13.503'
          stroke='#FF5457'
          strokeWidth='0.625'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M13.4129 10.6422C13.9337 10.6422 14.3559 10.2331 14.3559 9.72845C14.3559 9.2238 13.9337 8.8147 13.4129 8.8147C12.8921 8.8147 12.47 9.2238 12.47 9.72845C12.47 10.2331 12.8921 10.6422 13.4129 10.6422Z'
          stroke='#FF5457'
          strokeWidth='0.625'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M7.16638 16.35L5.17688 16.646L5.48238 18.5739L7.47189 18.2778L7.16638 16.35Z'
          stroke='#FF5457'
          strokeWidth='0.625'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8.83777 19.5303L13.8927 18.7788'
          stroke='#FF5457'
          strokeWidth='0.625'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M9.05591 20.9104L12.2902 20.4287'
          stroke='#FF5457'
          strokeWidth='0.625'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M7.63513 19.3107L5.64563 19.6067L5.95113 21.5346L7.94064 21.2385L7.63513 19.3107Z'
          stroke='#FF5457'
          strokeWidth='0.625'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M9.30579 22.492L14.3625 21.7405'
          stroke='#FF5457'
          strokeWidth='0.625'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M9.52649 23.872L12.759 23.3904'
          stroke='#FF5457'
          strokeWidth='0.625'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8.10401 22.2733L6.1145 22.5693L6.42001 24.4972L8.40951 24.2012L8.10401 22.2733Z'
          stroke='#FF5457'
          strokeWidth='0.625'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M28.7823 5.22299L30.4656 4.97363'
          stroke='#FF5457'
          strokeWidth='0.625'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M29.9177 12.3827L31.5992 12.1333'
          stroke='#FF5457'
          strokeWidth='0.625'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M29.553 5.1084L30.6881 12.2681'
          stroke='#FF5457'
          strokeWidth='0.625'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M21.4603 14.3843L21.7159 15.9966'
          stroke='#FF5457'
          strokeWidth='0.625'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M28.9709 13.2656L29.2265 14.8779'
          stroke='#FF5457'
          strokeWidth='0.625'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M21.5874 15.1905L29.0976 14.0718'
          stroke='#FF5457'
          strokeWidth='0.625'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs></defs>
    </>
  );
};
