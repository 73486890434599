import React from 'react';

export const Video = ({ className }) => {
  return (
    <>
      <g className={className}>
        <path
          d='M0.917725 14.3176L28.8801 8.82742L31.8852 25.9376C32.3015 28.308 30.8194 30.5898 28.5776 31.03L8.73916 34.9251C6.49727 35.3653 4.33919 33.7982 3.92287 31.4278L0.917725 14.3176Z'
          fill='#4F4FC0'
        />
        <path
          d='M0.917725 14.3176L28.8801 8.82742L31.8852 25.9376C32.3015 28.308 30.8194 30.5898 28.5776 31.03L8.73916 34.9251C6.49727 35.3653 4.33919 33.7982 3.92287 31.4278L0.917725 14.3176Z'
          fill='url(#paint0_linear_3544_50494)'
        />
        <path
          d='M24.9769 0.455457L0.793513 10.3904C0.153482 10.6541 -0.163918 11.4165 0.0854677 12.0932L0.953085 14.5152L27.4821 3.58179L26.5875 1.20408C26.3381 0.527369 25.617 0.191779 24.9769 0.455457Z'
          fill='#4F4FC0'
        />
        <path
          d='M24.9769 0.455457L0.793513 10.3904C0.153482 10.6541 -0.163918 11.4165 0.0854677 12.0932L0.953085 14.5152L27.4821 3.58179L26.5875 1.20408C26.3381 0.527369 25.617 0.191779 24.9769 0.455457Z'
          fill='url(#paint1_linear_3544_50494)'
        />
        <path
          d='M1.77478 14.1494L29.7371 8.65921L32.7423 25.7694C33.1586 28.1398 31.6765 30.4216 29.4346 30.8617L9.59708 34.7567C7.35518 35.1969 5.1971 33.6298 4.78078 31.2595L1.77564 14.1492L1.77478 14.1494Z'
          fill='#8296F5'
        />
        <path
          d='M1.77478 14.1494L29.7371 8.65921L32.7423 25.7694C33.1586 28.1398 31.6765 30.4216 29.4346 30.8617L9.59708 34.7567C7.35518 35.1969 5.1971 33.6298 4.78078 31.2595L1.77564 14.1492L1.77478 14.1494Z'
          fill='url(#paint2_linear_3544_50494)'
        />
        <path
          d='M25.8 0.0893008L1.61663 10.0242C0.976602 10.2879 0.659202 11.0504 0.908588 11.7271L1.77621 14.149L28.3052 3.21656L27.4106 0.838847C27.1612 0.162135 26.4401 -0.173455 25.8 0.0902228V0.0893008Z'
          fill='#8296F5'
        />
        <path
          d='M25.7905 0.0919318L1.60626 10.0269C0.966226 10.2905 0.648826 11.053 0.898212 11.7297L1.76583 14.1517L28.2949 3.21827L27.4002 0.840555C27.1508 0.163844 26.4297 -0.171746 25.7897 0.0919318H25.7905Z'
          fill='url(#paint3_linear_3544_50494)'
        />
        <path
          d='M25.8 0.0893008L1.61663 10.0242C0.976602 10.2879 0.659202 11.0504 0.908588 11.7271L1.77621 14.149L28.3052 3.21656L27.4106 0.838847C27.1612 0.162135 26.4401 -0.173455 25.8 0.0902228V0.0893008Z'
          fill='url(#paint4_linear_3544_50494)'
        />
        <path
          d='M8.87151 16.6715L11.7979 12.165L8.20445 12.8713L5.27722 17.3768L8.87151 16.6715Z'
          fill='white'
        />
        <path
          d='M17.3244 15.0119L20.2507 10.5054L16.6565 11.2116L13.7301 15.7172L17.3244 15.0119Z'
          fill='white'
        />
        <path
          d='M25.7767 13.3524L28.703 8.84595L25.1096 9.55216L22.1824 14.0577L25.7767 13.3524Z'
          fill='white'
        />
        <path
          d='M6.6008 7.98584L11.3322 10.1884L7.92534 11.5888L3.19312 9.38628L6.6008 7.98584Z'
          fill='white'
        />
        <path
          d='M14.6135 4.69263L19.3457 6.89517L15.938 8.29561L11.2067 6.09307L14.6135 4.69263Z'
          fill='white'
        />
        <path
          d='M22.627 1.39941L27.3584 3.60195L23.9516 5.0024L19.2194 2.79986L22.627 1.39941Z'
          fill='white'
        />
        <path
          d='M10.1874 28.8376C10.1316 28.8486 10.0941 28.9058 10.1045 28.9648C10.115 29.0238 10.1691 29.0635 10.2249 29.0524L26.4497 25.8671C26.5056 25.856 26.543 25.7988 26.5326 25.7398C26.5221 25.6808 26.4681 25.6412 26.4123 25.6522L10.1874 28.8376Z'
          fill='#A6B9FF'
        />
        <path
          d='M28.8818 20.5529L22.3184 21.8418C22.3324 21.8197 22.3385 21.7911 22.3333 21.7635L21.5162 17.1104L28.0517 15.827C28.1075 15.816 28.145 15.7588 28.1345 15.6998C28.1241 15.6408 28.07 15.6011 28.0142 15.6122L5.75 19.9841C5.69419 19.9952 5.65669 20.0523 5.66716 20.1113C5.67762 20.1703 5.73168 20.21 5.78749 20.1989L12.7467 18.8326L13.5638 23.4857C13.569 23.5142 13.5838 23.5373 13.6048 23.5539L6.61761 24.9257C6.56181 24.9368 6.52431 24.994 6.53478 25.053C6.54524 25.112 6.5993 25.1516 6.65511 25.1406L13.6144 23.7742L13.8167 23.7346L22.1807 22.0926L22.383 22.0529L28.9184 20.7696C28.9742 20.7585 29.0117 20.7014 29.0013 20.6424C28.9908 20.5834 28.9367 20.5437 28.8809 20.5548L28.8818 20.5529ZM13.7513 23.5235C13.7652 23.5013 13.7713 23.4728 13.7661 23.4451L12.949 18.792L21.313 17.15L22.1301 21.8031C22.1353 21.8317 22.1501 21.8547 22.1711 21.8713L13.7513 23.5244V23.5235Z'
          fill='#A6B9FF'
        />
        <path
          d='M1.36041 11.7908L2.12513 16.1452C2.17658 16.4366 2.65006 16.4181 3.0503 16.3397L6.44229 15.6741C6.91229 15.5819 7.01082 14.9163 6.5914 14.6765L2.24721 11.0708C1.80337 11.0773 1.2828 11.3483 1.36041 11.7908Z'
          fill='#4F4FC0'
        />
        <path
          d='M1.82157 11.6995L2.55752 15.8907C2.60897 16.1821 2.87318 16.3738 3.14785 16.3194L6.90346 15.5819C7.37345 15.4897 7.47199 14.824 7.05257 14.5843L2.56101 11.1307C2.18606 10.9159 1.74397 11.257 1.82157 11.6995Z'
          fill='#7E98FD'
        />
        <path
          d='M1.82157 11.6995L2.55752 15.8907C2.60897 16.1821 2.87318 16.3738 3.14785 16.3194L6.90346 15.5819C7.37345 15.4897 7.47199 14.824 7.05257 14.5843L2.56101 11.1307C2.18606 10.9159 1.74397 11.257 1.82157 11.6995Z'
          fill='url(#paint5_linear_3544_50494)'
        />
        <g style={{ mixBlendMode: 'soft-light' }} opacity='0.5'>
          <path
            d='M3.8106 14.0973C4.00034 14.0973 4.15416 13.9347 4.15416 13.7341C4.15416 13.5335 4.00034 13.3708 3.8106 13.3708C3.62086 13.3708 3.46704 13.5335 3.46704 13.7341C3.46704 13.9347 3.62086 14.0973 3.8106 14.0973Z'
            fill='white'
          />
        </g>
        <path
          d='M3.76442 14.1629C3.93297 14.1629 4.06961 14.0185 4.06961 13.8403C4.06961 13.662 3.93297 13.5176 3.76442 13.5176C3.59587 13.5176 3.45923 13.662 3.45923 13.8403C3.45923 14.0185 3.59587 14.1629 3.76442 14.1629Z'
          fill='#132132'
        />
        <path
          d='M8.00664 20.5648C8.00228 20.5611 7.99356 20.5574 7.98223 20.5528C7.97089 20.5482 7.95694 20.5436 7.94124 20.539C7.92555 20.5344 7.90811 20.5325 7.8898 20.5307C7.87148 20.5288 7.85404 20.5307 7.83573 20.5344C7.80434 20.5408 7.78167 20.551 7.76859 20.5666C7.75464 20.5814 7.75028 20.6007 7.75377 20.6238C7.75638 20.6404 7.76423 20.6533 7.77557 20.6616C7.7869 20.6699 7.80347 20.6754 7.82527 20.6782C7.8462 20.681 7.87323 20.6837 7.90462 20.6865C7.9456 20.6893 7.98135 20.6948 8.01274 20.7031C8.04414 20.7114 8.06942 20.7261 8.08861 20.7464C8.10779 20.7667 8.12087 20.7962 8.12785 20.8349C8.13395 20.8681 8.13308 20.8967 8.12523 20.9225C8.11738 20.9483 8.10517 20.9704 8.08686 20.9898C8.06942 21.0092 8.04762 21.0239 8.02234 21.0359C7.99705 21.0479 7.96915 21.0571 7.94037 21.0626C7.9116 21.0682 7.88108 21.0709 7.84968 21.07C7.81917 21.07 7.78865 21.0654 7.75987 21.059C7.7311 21.0525 7.70319 21.0424 7.67616 21.0304L7.70842 20.9151C7.71453 20.9197 7.72412 20.9244 7.73894 20.9308C7.7529 20.9373 7.77033 20.9428 7.79039 20.9474C7.81045 20.952 7.83137 20.9557 7.85404 20.9575C7.87672 20.9594 7.89939 20.9575 7.92206 20.9539C7.95345 20.9474 7.97699 20.9373 7.99007 20.9234C8.00402 20.9096 8.00839 20.8921 8.0049 20.8709C8.00141 20.8524 7.99269 20.8386 7.97787 20.8303C7.96304 20.822 7.94386 20.8156 7.91944 20.8128C7.89503 20.8091 7.86625 20.8063 7.83399 20.8036C7.79388 20.7999 7.76074 20.7934 7.73284 20.7842C7.70581 20.775 7.68401 20.7612 7.66744 20.7427C7.65087 20.7243 7.64041 20.6994 7.63518 20.6671C7.62733 20.6247 7.63082 20.5869 7.64564 20.5537C7.66047 20.5205 7.68226 20.4938 7.71278 20.4726C7.7433 20.4514 7.77818 20.4366 7.81829 20.4283C7.8462 20.4228 7.87323 20.421 7.89939 20.4228C7.92555 20.4247 7.94996 20.4283 7.97351 20.4348C7.99705 20.4412 8.01885 20.4486 8.03803 20.4578L8.00402 20.5666L8.00664 20.5648Z'
          fill='#A6B9FF'
        />
        <path
          d='M8.23851 20.6682C8.23153 20.6295 8.23153 20.5898 8.23851 20.5511C8.24548 20.5115 8.25856 20.4746 8.27862 20.4396C8.29867 20.4045 8.32571 20.3741 8.35797 20.3492C8.3911 20.3243 8.43034 20.3068 8.47656 20.2985C8.53149 20.2874 8.58032 20.2911 8.62479 20.3087C8.66926 20.3262 8.70414 20.3538 8.7303 20.3907L8.65357 20.4728C8.63962 20.4516 8.62305 20.435 8.60474 20.4248C8.58643 20.4147 8.56724 20.4082 8.54719 20.4055C8.52713 20.4036 8.50882 20.4036 8.49138 20.4073C8.46261 20.4128 8.43819 20.4239 8.41901 20.4405C8.39982 20.4571 8.385 20.4764 8.37366 20.4995C8.36233 20.5225 8.35623 20.5465 8.35361 20.5732C8.35099 20.5991 8.35187 20.6249 8.35622 20.6507C8.36058 20.6774 8.37018 20.7032 8.38238 20.7272C8.39546 20.7512 8.41116 20.7715 8.43034 20.7881C8.44953 20.8047 8.47045 20.8166 8.494 20.824C8.51754 20.8314 8.54196 20.8323 8.56812 20.8277C8.58555 20.824 8.60387 20.8176 8.62131 20.8074C8.63875 20.7973 8.65444 20.7835 8.66839 20.7659C8.68234 20.7484 8.69194 20.7272 8.69804 20.7023L8.80181 20.742C8.79483 20.7752 8.78001 20.8056 8.75733 20.8332C8.73466 20.8609 8.70763 20.883 8.67711 20.9005C8.64659 20.9181 8.6152 20.93 8.58294 20.9365C8.54021 20.9448 8.5001 20.943 8.46261 20.931C8.42424 20.919 8.39023 20.9005 8.35884 20.8747C8.32745 20.8489 8.30216 20.8176 8.28036 20.7825C8.25944 20.7466 8.24548 20.7088 8.23851 20.6691V20.6682Z'
          fill='#A6B9FF'
        />
        <path
          d='M9.38082 20.6636L9.4 20.7705L8.98755 20.8517L8.87769 20.2238L9.28228 20.1445L9.30147 20.2515L9.01197 20.3086L9.03813 20.4598L9.28752 20.411L9.30495 20.5096L9.05557 20.5585L9.08434 20.7217L9.38082 20.6636Z'
          fill='#A6B9FF'
        />
        <path
          d='M9.61784 20.3105L9.68847 20.7134L9.57337 20.7365L9.4635 20.1086L9.55593 20.0902L9.93437 20.4442L9.86199 20.0312L9.97622 20.009L10.0861 20.636L9.99105 20.6544L9.61784 20.3105Z'
          fill='#A6B9FF'
        />
        <path
          d='M10.6888 20.4063L10.708 20.5132L10.2955 20.5943L10.1857 19.9665L10.5903 19.8872L10.6094 19.9942L10.32 20.0513L10.3461 20.2025L10.5955 20.1537L10.6129 20.2523L10.3636 20.3012L10.3923 20.4643L10.6888 20.4063Z'
          fill='#A6B9FF'
        />
        <path
          d='M16.3183 18.8777L16.1291 18.9146L16.2206 19.4355L16.1064 19.4576L16.0148 18.9367L15.8239 18.9745L15.8047 18.8676L16.2991 18.7708L16.3183 18.8777Z'
          fill='#A6B9FF'
        />
        <path
          d='M16.5642 18.7191L16.681 18.696L17.0098 19.2806L16.8912 19.3036L16.8144 19.157L16.5964 19.1994L16.5746 19.3654L16.4561 19.3884L16.5651 18.7173L16.5642 18.7191ZM16.7717 19.0768L16.6444 18.8334L16.6078 19.1082L16.7717 19.0759V19.0768Z'
          fill='#A6B9FF'
        />
        <path
          d='M17.158 19.2512L17.0481 18.6234L17.1632 18.6003L17.2155 18.899L17.4117 18.5505L17.539 18.5256L17.3603 18.8502L17.6628 19.1516L17.532 19.1775L17.3001 18.9424L17.2391 19.0401L17.2722 19.2282L17.1571 19.2512H17.158Z'
          fill='#A6B9FF'
        />
        <path
          d='M18.1948 18.9378L18.214 19.0447L17.8015 19.1258L17.6917 18.498L18.0962 18.4187L18.1154 18.5256L17.8259 18.5828L17.8521 18.734L18.1015 18.6851L18.1189 18.7838L17.8695 18.8327L17.8983 18.9958L18.1948 18.9378Z'
          fill='#A6B9FF'
        />
        <path
          d='M9.74695 25.7508L9.63708 25.123L9.85246 25.0806C9.91699 25.0677 9.97367 25.0714 10.0225 25.0907C10.0713 25.1101 10.1106 25.1414 10.1402 25.1848C10.1699 25.2281 10.1908 25.2788 10.2004 25.336C10.2117 25.3996 10.2091 25.4577 10.1934 25.5093C10.1777 25.5609 10.1507 25.6043 10.1114 25.6393C10.0722 25.6734 10.0225 25.6974 9.9632 25.7084L9.74783 25.7508H9.74695ZM10.0835 25.359C10.0766 25.3184 10.0635 25.2843 10.0434 25.2558C10.0242 25.2272 9.99983 25.2069 9.97018 25.1949C9.94053 25.1829 9.9074 25.1802 9.8699 25.1875L9.76963 25.2069L9.842 25.6208L9.94228 25.6015C9.98064 25.5941 10.0112 25.5784 10.0338 25.5554C10.0565 25.5323 10.0722 25.5038 10.08 25.4696C10.0879 25.4355 10.0888 25.3987 10.0818 25.3599L10.0835 25.359Z'
          fill='#A6B9FF'
        />
        <path
          d='M10.4576 24.9618L10.5745 24.9387L10.9032 25.5232L10.7846 25.5463L10.7079 25.3997L10.4899 25.4421L10.4681 25.6081L10.3495 25.6311L10.4585 24.9599L10.4576 24.9618ZM10.6651 25.3195L10.5378 25.0761L10.5012 25.3508L10.6651 25.3186V25.3195Z'
          fill='#A6B9FF'
        />
        <path
          d='M11.3488 24.8972L11.1596 24.9341L11.2511 25.455L11.1369 25.4771L11.0454 24.9562L10.8544 24.994L10.8352 24.8871L11.3296 24.7903L11.3488 24.8972Z'
          fill='#A6B9FF'
        />
        <path
          d='M11.9924 25.1995L12.0116 25.3064L11.5991 25.3876L11.4893 24.7597L11.8939 24.6804L11.913 24.7874L11.6235 24.8445L11.6497 24.9957L11.8991 24.9469L11.9165 25.0455L11.6671 25.0944L11.6959 25.2576L11.9924 25.1995Z'
          fill='#A6B9FF'
        />
        <path
          d='M10.5075 30.4723L10.3976 29.8444L10.613 29.802C10.6775 29.7891 10.7342 29.7928 10.783 29.8122C10.8318 29.8315 10.8711 29.8629 10.9007 29.9062C10.9304 29.9495 10.9513 30.0002 10.9609 30.0574C10.9722 30.121 10.9696 30.1791 10.9539 30.2307C10.9382 30.2824 10.9112 30.3257 10.8719 30.3607C10.8327 30.3948 10.783 30.4188 10.7237 30.4299L10.5083 30.4723H10.5075ZM10.844 30.0804C10.8371 30.0399 10.824 30.0058 10.8039 29.9772C10.7847 29.9486 10.7603 29.9283 10.7307 29.9163C10.701 29.9044 10.6679 29.9016 10.6304 29.909L10.5301 29.9283L10.6025 30.3423L10.7028 30.3229C10.7411 30.3155 10.7717 30.2999 10.7943 30.2768C10.817 30.2538 10.8327 30.2252 10.8405 30.1911C10.8484 30.157 10.8493 30.1201 10.8423 30.0814L10.844 30.0804Z'
          fill='#A6B9FF'
        />
        <path
          d='M11.1893 30.3386L11.0795 29.7108L11.1946 29.6877L11.3044 30.3156L11.1893 30.3386Z'
          fill='#A6B9FF'
        />
        <path
          d='M11.5118 30.2748L11.402 29.647L11.6653 29.5954C11.6923 29.5898 11.7185 29.5907 11.7429 29.5981C11.7682 29.6055 11.7909 29.6175 11.8118 29.6341C11.8327 29.6507 11.8493 29.671 11.8633 29.694C11.8772 29.717 11.8859 29.741 11.8903 29.7678C11.8946 29.7945 11.8955 29.8203 11.8903 29.8461C11.8859 29.8719 11.8772 29.8959 11.8641 29.9171C11.851 29.9392 11.8345 29.9568 11.8144 29.9715L11.9914 30.1808L11.8641 30.2057L11.7054 30.0194L11.5903 30.0425L11.6269 30.2527L11.5118 30.2758V30.2748ZM11.5711 29.9346L11.7159 29.906C11.7307 29.9033 11.742 29.8959 11.7516 29.8848C11.7612 29.8738 11.7682 29.86 11.7717 29.8424C11.7761 29.8258 11.7761 29.8083 11.7726 29.789C11.7691 29.7696 11.7621 29.753 11.7516 29.7392C11.7412 29.7253 11.729 29.7152 11.7159 29.7088C11.7028 29.7023 11.6889 29.7005 11.6749 29.7032L11.5345 29.7309L11.5703 29.9346H11.5711Z'
          fill='#A6B9FF'
        />
        <path
          d='M12.5451 29.9614L12.5643 30.0684L12.1519 30.1495L12.042 29.5217L12.4466 29.4424L12.4658 29.5493L12.1763 29.6065L12.2024 29.7577L12.4518 29.7088L12.4693 29.8075L12.2199 29.8563L12.2487 30.0195L12.5451 29.9614Z'
          fill='#A6B9FF'
        />
        <path
          d='M12.635 29.7246C12.628 29.6859 12.628 29.6462 12.635 29.6075C12.642 29.5679 12.655 29.531 12.6751 29.496C12.6952 29.4609 12.7222 29.4305 12.7545 29.4056C12.7876 29.3807 12.8268 29.3632 12.873 29.3549C12.928 29.3438 12.9768 29.3475 13.0213 29.365C13.0658 29.3826 13.1006 29.4102 13.1268 29.4471L13.0501 29.5292C13.0361 29.508 13.0195 29.4914 13.0012 29.4812C12.9829 29.4711 12.9637 29.4646 12.9437 29.4619C12.9236 29.46 12.9053 29.46 12.8879 29.4637C12.8591 29.4692 12.8347 29.4803 12.8155 29.4969C12.7963 29.5135 12.7815 29.5328 12.7701 29.5559C12.7588 29.5789 12.7527 29.6029 12.7501 29.6296C12.7475 29.6555 12.7484 29.6813 12.7527 29.7071C12.7571 29.7338 12.7667 29.7596 12.7789 29.7836C12.7919 29.8076 12.8076 29.8279 12.8268 29.8445C12.846 29.8611 12.8669 29.873 12.8905 29.8804C12.914 29.8878 12.9384 29.8887 12.9646 29.8841C12.982 29.8804 13.0004 29.874 13.0178 29.8638C13.0352 29.8537 13.0509 29.8399 13.0649 29.8223C13.0788 29.8048 13.0884 29.7836 13.0945 29.7587L13.1983 29.7984C13.1913 29.8316 13.1765 29.862 13.1538 29.8896C13.1311 29.9173 13.1041 29.9394 13.0736 29.9569C13.0431 29.9745 13.0117 29.9864 12.9794 29.9929C12.9367 30.0012 12.8966 29.9993 12.8591 29.9874C12.8207 29.9754 12.7867 29.9569 12.7553 29.9311C12.7239 29.9053 12.6986 29.874 12.6768 29.8389C12.6559 29.803 12.642 29.7652 12.635 29.7255V29.7246Z'
          fill='#A6B9FF'
        />
        <path
          d='M13.7364 29.2996L13.5472 29.3365L13.6387 29.8574L13.5245 29.8795L13.4329 29.3586L13.242 29.3964L13.2228 29.2894L13.7172 29.1926L13.7364 29.2996Z'
          fill='#A6B9FF'
        />
        <path
          d='M14.2263 29.7465C14.1827 29.7548 14.1417 29.7538 14.1034 29.7428C14.065 29.7317 14.0301 29.7142 13.9996 29.6884C13.9691 29.6626 13.9429 29.6331 13.922 29.5971C13.9011 29.5621 13.8871 29.5243 13.8801 29.4837C13.8732 29.4431 13.8732 29.4007 13.881 29.3592C13.8888 29.3187 13.9037 29.2809 13.9237 29.2458C13.9447 29.2108 13.9708 29.1813 14.0039 29.1573C14.0371 29.1334 14.0737 29.1168 14.1156 29.1085C14.1574 29.1002 14.1993 29.1011 14.2376 29.1131C14.276 29.1241 14.3109 29.1426 14.3414 29.1684C14.3719 29.1942 14.3972 29.2246 14.4173 29.2597C14.4373 29.2947 14.4513 29.3325 14.4582 29.3721C14.4652 29.4118 14.4652 29.4542 14.4582 29.4957C14.4504 29.5363 14.4364 29.5741 14.4155 29.6091C14.3946 29.6441 14.3684 29.6736 14.3362 29.6976C14.3039 29.7216 14.2664 29.7382 14.2246 29.7455L14.2263 29.7465ZM13.9978 29.4616C14.0022 29.4883 14.0109 29.5141 14.0231 29.5381C14.0353 29.5621 14.051 29.5824 14.0685 29.5989C14.0868 29.6155 14.1077 29.6275 14.1313 29.6349C14.1548 29.6423 14.1801 29.6432 14.2071 29.6377C14.2341 29.6321 14.2594 29.6211 14.2786 29.6054C14.2978 29.5897 14.3126 29.5694 14.324 29.5464C14.3353 29.5233 14.3423 29.4985 14.3449 29.4717C14.3475 29.445 14.3466 29.4192 14.3423 29.3934C14.3379 29.3666 14.3292 29.3408 14.317 29.3178C14.3048 29.2947 14.2891 29.2744 14.2708 29.2569C14.2525 29.2403 14.2315 29.2283 14.208 29.2209C14.1844 29.2136 14.1592 29.2127 14.1321 29.2182C14.1051 29.2237 14.0816 29.2348 14.0624 29.2504C14.0432 29.2661 14.0275 29.2864 14.0162 29.3085C14.0048 29.3316 13.9978 29.3556 13.9944 29.3823C13.9909 29.409 13.9917 29.4348 13.9961 29.4607L13.9978 29.4616Z'
          fill='#A6B9FF'
        />
        <path
          d='M14.6885 29.6508L14.5786 29.023L14.842 28.9713C14.869 28.9658 14.8951 28.9667 14.9196 28.9741C14.9448 28.9815 14.9675 28.9935 14.9884 29.0101C15.0094 29.0266 15.0259 29.0469 15.0399 29.07C15.0538 29.093 15.0626 29.117 15.0669 29.1437C15.0713 29.1705 15.0722 29.1963 15.0669 29.2221C15.0626 29.2479 15.0538 29.2719 15.0408 29.2931C15.0277 29.3152 15.0111 29.3327 14.9911 29.3475L15.1681 29.5568L15.0408 29.5817L14.8821 29.3954L14.767 29.4185L14.8036 29.6287L14.6885 29.6517V29.6508ZM14.7478 29.3106L14.8925 29.282C14.9073 29.2793 14.9187 29.2719 14.9283 29.2608C14.9379 29.2498 14.9448 29.2359 14.9483 29.2184C14.9527 29.2018 14.9527 29.1843 14.9492 29.1649C14.9457 29.1456 14.9387 29.129 14.9283 29.1152C14.9178 29.1013 14.9056 29.0912 14.8925 29.0847C14.8794 29.0783 14.8655 29.0764 14.8515 29.0792L14.7112 29.1069L14.7469 29.3106H14.7478Z'
          fill='#A6B9FF'
        />
        <path
          d='M23.7578 17.9511L23.6479 17.3233L23.9113 17.2716C23.9383 17.2661 23.9645 17.267 23.9889 17.2744C24.0142 17.2818 24.0368 17.2937 24.0578 17.3103C24.0787 17.3269 24.0953 17.3472 24.1092 17.3703C24.1232 17.3933 24.1319 17.4173 24.1363 17.444C24.1406 17.4708 24.1415 17.4966 24.1363 17.5224C24.1319 17.5482 24.1232 17.5722 24.1101 17.5934C24.097 17.6155 24.0804 17.633 24.0604 17.6478L24.2374 17.8571L24.1101 17.882L23.9514 17.6957L23.8363 17.7188L23.8729 17.929L23.7578 17.952V17.9511ZM23.8171 17.6109L23.9619 17.5823C23.9767 17.5796 23.988 17.5722 23.9976 17.5611C24.0072 17.5501 24.0142 17.5362 24.0177 17.5187C24.022 17.5021 24.022 17.4846 24.0185 17.4652C24.0151 17.4459 24.0081 17.4293 23.9976 17.4154C23.9871 17.4016 23.9749 17.3915 23.9619 17.385C23.9488 17.3786 23.9348 17.3767 23.9209 17.3795L23.7805 17.4071L23.8162 17.6109H23.8171Z'
          fill='#A6B9FF'
        />
        <path
          d='M24.6482 17.7806C24.6046 17.7889 24.5636 17.788 24.5252 17.777C24.4869 17.7659 24.452 17.7484 24.4215 17.7226C24.3909 17.6967 24.3648 17.6672 24.3439 17.6313C24.3229 17.5962 24.309 17.5584 24.302 17.5179C24.295 17.4773 24.295 17.4349 24.3029 17.3934C24.3107 17.3529 24.3255 17.3151 24.3456 17.28C24.3665 17.245 24.3927 17.2155 24.4258 17.1915C24.459 17.1675 24.4956 17.1509 24.5374 17.1427C24.5793 17.1344 24.6211 17.1353 24.6595 17.1473C24.6979 17.1583 24.7328 17.1768 24.7633 17.2026C24.7938 17.2284 24.8191 17.2588 24.8391 17.2938C24.8592 17.3289 24.8731 17.3667 24.8801 17.4063C24.8871 17.446 24.8871 17.4884 24.8801 17.5299C24.8723 17.5704 24.8583 17.6082 24.8374 17.6433C24.8165 17.6783 24.7903 17.7078 24.758 17.7318C24.7258 17.7557 24.6883 17.7723 24.6464 17.7797L24.6482 17.7806ZM24.4197 17.4958C24.4241 17.5225 24.4328 17.5483 24.445 17.5723C24.4572 17.5963 24.4729 17.6165 24.4903 17.6331C24.5087 17.6497 24.5296 17.6617 24.5531 17.6691C24.5767 17.6765 24.602 17.6774 24.629 17.6718C24.656 17.6663 24.6813 17.6553 24.7005 17.6396C24.7197 17.6239 24.7345 17.6036 24.7458 17.5806C24.7572 17.5575 24.7642 17.5326 24.7668 17.5059C24.7694 17.4792 24.7685 17.4533 24.7642 17.4275C24.7598 17.4008 24.7511 17.375 24.7389 17.3519C24.7267 17.3289 24.711 17.3086 24.6926 17.2911C24.6743 17.2745 24.6534 17.2625 24.6299 17.2551C24.6063 17.2478 24.581 17.2468 24.554 17.2524C24.527 17.2579 24.5034 17.269 24.4842 17.2846C24.4651 17.3003 24.4494 17.3206 24.438 17.3427C24.4267 17.3658 24.4197 17.3897 24.4162 17.4165C24.4127 17.4432 24.4136 17.469 24.418 17.4948L24.4197 17.4958Z'
          fill='#A6B9FF'
        />
        <path
          d='M25.1094 17.6856L24.9995 17.0577L25.1146 17.0347L25.2062 17.5556L25.5087 17.4966L25.5279 17.6035L25.1094 17.6856Z'
          fill='#A6B9FF'
        />
        <path
          d='M25.6815 17.5733L25.5717 16.9454L25.6868 16.9224L25.7783 17.4433L26.0809 17.3843L26.1001 17.4912L25.6815 17.5733Z'
          fill='#A6B9FF'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_3544_50494'
          x1='18.6577'
          y1='32.9786'
          x2='14.4712'
          y2='11.6565'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.19' stopColor='#132132' stopOpacity='0' />
          <stop offset='0.34' stopColor='#132132' stopOpacity='0.3' />
          <stop offset='0.65' stopColor='#132132' stopOpacity='0.81' />
          <stop offset='0.84' stopColor='#132132' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_3544_50494'
          x1='14.4783'
          y1='11.8583'
          x2='12.7076'
          y2='2.84011'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.16' stopColor='#132132' />
          <stop offset='0.17' stopColor='#132132' stopOpacity='0.76' />
          <stop offset='0.45' stopColor='#132132' stopOpacity='0.26' />
          <stop offset='0.64' stopColor='#132132' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_3544_50494'
          x1='7.79862'
          y1='8.70665'
          x2='28.9291'
          y2='32.0905'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.18' stopColor='#4F4FC0' stopOpacity='0' />
          <stop offset='0.25' stopColor='#4F4FC0' stopOpacity='0.18' />
          <stop offset='0.41' stopColor='#4F4FC0' stopOpacity='0.62' />
          <stop offset='0.53' stopColor='#4F4FC0' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_3544_50494'
          x1='0.52144'
          y1='9.71196'
          x2='28.5704'
          y2='4.7856'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#4F4FC0' stopOpacity='0' />
          <stop offset='0.73' stopColor='#4F4FC0' />
        </linearGradient>
        <linearGradient
          id='paint4_linear_3544_50494'
          x1='0.53164'
          y1='9.71008'
          x2='28.5806'
          y2='4.78371'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#4F4FC0' stopOpacity='0' />
          <stop offset='0.71' stopColor='#4F4FC0' />
        </linearGradient>
        <linearGradient
          id='paint5_linear_3544_50494'
          x1='2.19002'
          y1='13.7946'
          x2='6.95892'
          y2='12.9571'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#4F4FC0' stopOpacity='0' />
          <stop offset='0.71' stopColor='#4F4FC0' />
        </linearGradient>
        <clipPath id='clip0_3544_50494'>
          <rect width='32.8125' height='35' fill='white' />
        </clipPath>
      </defs>
    </>
  );
};
