import React from 'react';
import { theme } from '@konsus/superside-kit';
import { textAnimationsStyle, useText } from '../hooks/useText';
import { glowStyle } from '../commonStyles';
import { type LightBeamProps } from '../types';

export const LightBeam = ({ showText }: LightBeamProps) => {
  const { textCls } = useText(showText);

  return (
    <>
      <style dangerouslySetInnerHTML={{ __html: textAnimationsStyle }} />
      <g className={glowStyle}>
        <path
          d='M54.2238 479C52.2317 479 50.4582 477.597 50.0738 475.584C49.6384 473.306 51.1475 471.111 53.4411 470.68L375.982 410.073C378.28 409.641 380.49 411.139 380.924 413.414C381.359 415.692 379.85 417.887 377.557 418.317L55.0171 478.926C54.7509 478.977 54.4865 479 54.2256 479H54.2238Z'
          fill='url(#paint16_linear_38_451)'
          mask='url(#prism-mask)'
        />
        <path
          d='M82.6462 472.601C81.2094 472.601 79.9295 471.589 79.6527 470.137C79.3389 468.494 80.4267 466.909 82.0821 466.6L377.381 410.897C379.038 410.586 380.633 411.665 380.945 413.308C381.259 414.95 380.171 416.535 378.516 416.845L83.2174 472.549C83.0252 472.586 82.8349 472.603 82.6462 472.603V472.601Z'
          fill='url(#paint17_linear_38_451)'
          mask='url(#prism-mask)'
        />
        <path
          d='M379.835 413.348L54.2293 474.804'
          stroke='url(#paint18_linear_38_451)'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
          mask='url(#prism-mask)'
        />
      </g>

      {showText && (
        <text
          mask='url(#text-mask)'
          style={{
            fill: theme.colors['alabaster-white'],
            fontFamily: 'Raleway',
            fontSize: '16px',
            fontWeight: 600,
            letterSpacing: '0.15em',
            textTransform: 'uppercase'
          }}
        >
          <tspan x='187' y='362'>
            your creative
          </tspan>
          <tspan x='233' y='386'>
            needs
          </tspan>
        </text>
      )}

      <path
        d='M262.667 410C262.667 410.736 263.264 411.333 264 411.333C264.736 411.333 265.333 410.736 265.333 410C265.333 409.264 264.736 408.667 264 408.667C263.264 408.667 262.667 409.264 262.667 410ZM263.75 410V437H264.25V410L263.75 410Z'
        fill='white'
        mask='url(#prism-mask)'
      />
      <defs>
        <mask id='text-mask'>
          <rect width='0' height='100%' fill='#FFFFFF' className={textCls} />
        </mask>
        <linearGradient
          id='paint16_linear_38_451'
          x1='49.9998'
          y1='444.5'
          x2='381'
          y2='444.5'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#006DAA' stopOpacity='0' />
          <stop offset='1' stopColor='#3C6DAA' />
        </linearGradient>
        <linearGradient
          id='paint17_linear_38_451'
          x1='79.5981'
          y1='441.722'
          x2='381'
          y2='441.722'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#6F9ED1' stopOpacity='0' />
          <stop offset='0.99' stopColor='#6F9ED1' />
        </linearGradient>
        <linearGradient
          id='paint18_linear_38_451'
          x1='53.5946'
          y1='444.075'
          x2='380.469'
          y2='444.075'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.09' stopColor='white' stopOpacity='0' />
          <stop offset='0.39' stopColor='white' />
          <stop offset='1' stopColor='white' />
        </linearGradient>
      </defs>
    </>
  );
};
