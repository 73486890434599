import React from 'react';
import { glowAnimation } from '../commonStyles';

export const RainbowSmall = () => {
  return (
    <>
      <g filter='url(#filter2_f_38_51)' className={glowAnimation}>
        <path
          d='M203.212 187.257L513.048 240.875V262L203.212 208.379'
          fill='url(#paint2_linear_38_51)'
          mask='url(#rainbow-mask)'
        />
        <path
          d='M203.212 166.131L513.048 219.752V240.875L203.212 187.256V187.251V166.131Z'
          fill='url(#paint3_linear_38_51)'
          mask='url(#rainbow-mask)'
        />
        <path
          d='M203.212 145.008L513.048 198.626V219.752L203.212 166.131V166.121V145.008Z'
          fill='url(#paint4_linear_38_51)'
          mask='url(#rainbow-mask)'
        />
        <path
          d='M203.212 123.87V123.883L513.048 177.504V198.626L203.212 145.008V144.993V123.87Z'
          fill='url(#paint5_linear_38_51)'
          mask='url(#rainbow-mask)'
        />
        <path
          d='M203.212 102.742V102.76L513.048 156.381V177.503L203.212 123.885V123.865V102.742Z'
          fill='url(#paint6_linear_38_51)'
          mask='url(#rainbow-mask)'
        />
      </g>
      <path
        d='M494 256L218 207.937V189L494 237.065'
        fill='url(#paint7_linear_38_51)'
        mask='url(#rainbow-mask)'
      />
      <path
        d='M494 237L218 188.935V170L494 218.063V218.068V237Z'
        fill='url(#paint8_linear_38_51)'
        mask='url(#rainbow-mask)'
      />
      <path
        d='M494 218L218 170.654V152L494 199.348V199.357V218Z'
        fill='url(#paint9_linear_38_51)'
        mask='url(#rainbow-mask)'
      />
      <path
        d='M494 199V198.989L218 151.649V133L494 180.338V180.351V199Z'
        fill='url(#paint10_linear_38_51)'
        mask='url(#rainbow-mask)'
      />
      <path
        d='M494 181V180.984L218 132.93V114L494 162.052V162.07V181Z'
        fill='url(#paint11_linear_38_51)'
        mask='url(#rainbow-mask)'
      />

      <defs>
        <filter id='filter2_f_38_51' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feGaussianBlur stdDeviation='17.5' result='effect1_foregroundBlur_38_51' />
        </filter>
        <linearGradient
          id='paint2_linear_38_51'
          x1='189.036'
          y1='187.47'
          x2='524.074'
          y2='242.05'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.411458' stopColor='#FF5457' />
          <stop offset='0.765625' stopColor='#FF5457' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_38_51'
          x1='211.246'
          y1='166.205'
          x2='526.91'
          y2='225.038'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.46875' stopColor='#FFBD00' />
          <stop offset='0.770833' stopColor='#FFBD00' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint4_linear_38_51'
          x1='203.212'
          y1='147.776'
          x2='531.635'
          y2='199.993'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.515625' stopColor='#00FF75' />
          <stop offset='0.78125' stopColor='#00FF75' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint5_linear_38_51'
          x1='203.212'
          y1='116.351'
          x2='537.069'
          y2='165.733'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.354167' stopColor='#00EFFF' />
          <stop offset='0.770833' stopColor='#00EFFF' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint6_linear_38_51'
          x1='203.212'
          y1='95.5591'
          x2='513.206'
          y2='99.3395'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.416667' stopColor='#6D99FF' />
          <stop offset='0.760417' stopColor='#6D99FF' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint7_linear_38_51'
          x1='359.354'
          y1='223.904'
          x2='83.5926'
          y2='166.847'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF5457' stopOpacity='0' />
          <stop offset='0.4' stopColor='#FF5457' />
          <stop offset='0.41' stopColor='#FF5457' stopOpacity='0.98' />
          <stop offset='0.63' stopColor='#FF5457' stopOpacity='0.57' />
          <stop offset='0.81' stopColor='#FF5457' stopOpacity='0.26' />
          <stop offset='0.94' stopColor='#FF5457' stopOpacity='0.07' />
          <stop offset='1' stopColor='#FF5457' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint8_linear_38_51'
          x1='355.595'
          y1='204.133'
          x2='79.834'
          y2='147.076'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFBD00' stopOpacity='0' />
          <stop offset='0.4' stopColor='#FFBD00' />
          <stop offset='0.41' stopColor='#FFBD00' stopOpacity='0.98' />
          <stop offset='0.63' stopColor='#FFBD00' stopOpacity='0.57' />
          <stop offset='0.81' stopColor='#FFBD00' stopOpacity='0.26' />
          <stop offset='0.94' stopColor='#FFBD00' stopOpacity='0.07' />
          <stop offset='1' stopColor='#FFBD00' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint9_linear_38_51'
          x1='351.836'
          y1='184.868'
          x2='76.4202'
          y2='127.02'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#00FF75' stopOpacity='0' />
          <stop offset='0.4' stopColor='#00FF75' />
          <stop offset='0.41' stopColor='#00FF75' stopOpacity='0.98' />
          <stop offset='0.63' stopColor='#00FF75' stopOpacity='0.57' />
          <stop offset='0.81' stopColor='#00FF75' stopOpacity='0.26' />
          <stop offset='0.94' stopColor='#00FF75' stopOpacity='0.07' />
          <stop offset='1' stopColor='#00FF75' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint10_linear_38_51'
          x1='348.077'
          y1='165.103'
          x2='72.6656'
          y2='107.246'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#00EFFF' stopOpacity='0' />
          <stop offset='0.4' stopColor='#00EFFF' />
          <stop offset='0.41' stopColor='#00EFFF' stopOpacity='0.98' />
          <stop offset='0.63' stopColor='#00EFFF' stopOpacity='0.57' />
          <stop offset='0.81' stopColor='#00EFFF' stopOpacity='0.26' />
          <stop offset='0.94' stopColor='#00EFFF' stopOpacity='0.07' />
          <stop offset='1' stopColor='#00EFFF' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint11_linear_38_51'
          x1='344.318'
          y1='145.821'
          x2='68.5627'
          y2='88.7521'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#6D99FF' stopOpacity='0' />
          <stop offset='0.22' stopColor='#6D99FF' stopOpacity='0.55' />
          <stop offset='0.4' stopColor='#6D99FF' />
          <stop offset='0.41' stopColor='#6D99FF' stopOpacity='0.98' />
          <stop offset='0.63' stopColor='#6D99FF' stopOpacity='0.57' />
          <stop offset='0.81' stopColor='#6D99FF' stopOpacity='0.26' />
          <stop offset='0.94' stopColor='#6D99FF' stopOpacity='0.07' />
          <stop offset='1' stopColor='#6D99FF' stopOpacity='0' />
        </linearGradient>
      </defs>
    </>
  );
};
