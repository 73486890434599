import { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

export function useScrollView() {
  const { ref: nodeRef, inView } = useInView({ threshold: 0.3 });
  const [rainbowCls, setRainbowCls] = useState('');
  const [lightPrismCls, setLightPrismCls] = useState('');
  const [showText, setShowText] = useState(false);

  useEffect(() => {
    if (inView) {
      setRainbowCls('rainbow-rect');
      setLightPrismCls('lightPrism-rect');

      setShowText(true);
    } else {
      setRainbowCls('');
      setLightPrismCls('');

      setShowText(false);
    }
  }, [inView]);

  return { nodeRef, inView, rainbowCls, lightPrismCls, showText };
}

export const animationsStyle = `
	@keyframes show {
		from {
			width: 0;
		}

		to {
			width: 100%;
		}
	}

	.lightPrism-rect {
		animation: show 3s ease-in-out;
		animation-fill-mode: forwards;
	}

	.rainbow-rect {
		animation: show 3s ease-in-out;
		animation-delay: 1.5s;
		animation-fill-mode: forwards;
	}

	.bubble-rect {
		animation: show 3s ease-in-out;
		animation-delay: 2.5s;
		animation-fill-mode: forwards;
	}
`;
