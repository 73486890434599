import React from 'react';
import { Box, useScreenSize } from '@konsus/superside-kit';
import { CaasLarge } from './CaasLarge';
import { CaasSmall } from './CaasSmall';
import { type CaasProps } from './types';

export const Caas = ({ timeouts }: CaasProps) => {
  const { breakpoints } = useScreenSize();
  const useLarge = !!breakpoints.medium;

  return (
    <Box>{useLarge ? <CaasLarge timeouts={timeouts} /> : <CaasSmall timeouts={timeouts} />}</Box>
  );
};
