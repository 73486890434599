import React, { useEffect, useState, useRef } from 'react';
import { type CaasProps } from '../types';
import styles from './index.module.css';
import { Bubble } from './Bubble';
import { bubblesFirstList, bubblesSecondList } from './constants';

const MIN_THRESHOLD = 3000;
const MAX_THRESHOLD = 5000;

const bubbleLists = [bubblesFirstList, bubblesSecondList];

type BubbleIconsProps = Pick<CaasProps, 'timeouts'> & {
  inView: boolean;
  collapsed?: boolean;
};

export const BubbleIcons = ({ inView, timeouts, collapsed = false }: BubbleIconsProps) => {
  const [hoverBubble, setHoverBubble] = useState<number | null>(null);
  const [items, setItems] = useState(bubblesFirstList);
  const [fadeState, setFadeState] = useState(Array(5).fill(styles.hiddenStyle));
  const [randomTimes, setRandomTimes] = useState<number[]>([]);
  const animationTimeouts = useRef<NodeJS.Timeout[]>([]);

  const { minTimeout = MIN_THRESHOLD, maxTimeout = MAX_THRESHOLD } = timeouts || {};

  const getRandomTimeDuration = () =>
    Math.floor(Math.random() * (maxTimeout - minTimeout)) + minTimeout;

  useEffect(() => {
    setRandomTimes(Array(5).fill(null).map(getRandomTimeDuration));

    return () => {
      animationTimeouts.current.forEach((timeoutId) => clearTimeout(timeoutId));
      setItems(bubblesFirstList);
      setFadeState(Array(5).fill(styles.hiddenStyle));
    };
  }, [inView]);

  useEffect(() => {
    if (inView) {
      const initialTimeout = setTimeout(() => {
        animationTimeouts.current = bubblesFirstList.map((_, index) => {
          return setTimeout(() => {
            displayItem(index, 0);
          }, index * 300);
        });
      }, 3200);

      return () => {
        clearTimeout(initialTimeout);
        animationTimeouts.current.forEach((timeoutId) => clearTimeout(timeoutId));
      };
    }

    return () => {
      animationTimeouts.current.forEach((timeoutId) => clearTimeout(timeoutId));
    };
  }, [inView]);

  const displayItem = (index: number, currentListIndex: number) => {
    const itemsList = bubbleLists[currentListIndex];

    setItems((prevItems) => {
      const newItems = [...prevItems];

      newItems[index] = itemsList[index];

      return newItems;
    });

    setFadeState((prevFadeState) => {
      const newFadeState = [...prevFadeState];

      newFadeState[index] = styles.shownStyle;

      return newFadeState;
    });

    setRandomTimes((prevRandomTimes) => {
      const newRandomTimes = [...prevRandomTimes];

      newRandomTimes[index] = getRandomTimeDuration();

      return newRandomTimes;
    });

    animationTimeouts.current[index] = setTimeout(() => {
      setFadeState((prevFadeState) => {
        const newFadeState = [...prevFadeState];

        newFadeState[index] = styles.hiddenStyle;

        return newFadeState;
      });

      if (inView) {
        animationTimeouts.current[index] = setTimeout(() => {
          displayItem(index, (currentListIndex + 1) % bubbleLists.length);
        }, randomTimes[index] / 4);
      }
    }, randomTimes[index]);
  };

  const handleBubbleHover = (index: number) => {
    clearTimeout(animationTimeouts.current[index]);
    setHoverBubble(index);
  };

  const handleBubbleUnHover = (index: number) => {
    if (inView) {
      animationTimeouts.current[index] = setTimeout(() => {
        displayItem(index, 0);
      }, randomTimes[index] / 4);
    }
    setHoverBubble(null);
  };

  return (
    <g
      className={'768:scale-100 <768:translate-x-5 <768:translate-y-5 <768:scale-[0.4]'}
      data-testid='bubble-icons'
    >
      {items.map(({ id, x, y, ...rest }, index) => {
        return (
          <Bubble
            key={id}
            data-testid={`bubble-${index}`}
            id={id}
            x={x}
            y={y}
            {...rest}
            hoverBubble={hoverBubble}
            className={fadeState[id]}
            style={{
              transformOrigin: `${x + 50}px ${y + 50}px`
            }}
            onMouseEnter={() => handleBubbleHover(index)}
            onMouseLeave={() => handleBubbleUnHover(index)}
            collapsed={collapsed}
          />
        );
      })}
    </g>
  );
};

export const shownStyle = styles.shownStyle;
export const hiddenStyle = styles.hiddenStyle;
