import React, { lazy, Suspense } from 'react';
import NextImage from '@konsus/image/index';
import { VisualAsset, type IVisualAsset } from '../../components';

const WistiaVideo = lazy(
  () =>
    import(/* webpackChunkName: "WistiaVideo" */ '@konsus/superside-kit/src/components/WistiaVideo')
);

export type MediaBoxProps = {
  isVisible: boolean;
  asset?: IVisualAsset;
  headerText?: string;
  mediaType?: 'image' | 'inlineVideo' | 'iframeVideo' | 'wistiaVideo';
  contentImage?: any;
  wistiaVideoId?: string;
};

export const MediaBox: React.FC<MediaBoxProps> = (props) => {
  // TODO: Simplify this component once sanity content is migrated to `asset` field
  if (props.asset && props.isVisible) {
    // @ts-expect-error fill not on props
    return <VisualAsset fill id='contentGrid' alt={props.headerText} {...props.asset} />;
  }

  if (props.mediaType === 'image' && props.contentImage && props.isVisible) {
    return <NextImage alt={props.headerText || ''} image={props.contentImage} />;
  }
  if (props.mediaType === 'wistiaVideo' && props.wistiaVideoId && props.isVisible) {
    return (
      <Suspense fallback={null /* FIXME: Add fallback */}>
        <WistiaVideo id={props.wistiaVideoId} />
      </Suspense>
    );
  }

  return null;
};
