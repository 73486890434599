import React from 'react';

export const ConceptCreation = ({ className }) => {
  return (
    <>
      <g className={className}>
        <path
          d='M32.1343 5.82849L31.1914 5.57463L32.1343 5.32077C32.1822 5.30785 32.226 5.28256 32.2611 5.24743C32.2962 5.2123 32.3215 5.16856 32.3344 5.12059L32.5883 4.17773L32.8422 5.12059C32.8551 5.16856 32.8804 5.2123 32.9155 5.24743C32.9506 5.28256 32.9944 5.30785 33.0423 5.32077L33.9852 5.57463L33.0423 5.82849C32.9944 5.84141 32.9506 5.8667 32.9155 5.90183C32.8804 5.93696 32.8551 5.9807 32.8422 6.02868L32.5883 6.97153L32.3344 6.02868C32.3215 5.9807 32.2962 5.93696 32.2611 5.90183C32.226 5.8667 32.1822 5.84141 32.1343 5.82849Z'
          fill='#4F4FC8'
        />
        <path
          d='M9.61084 31.8465L11.2451 32.2865L9.61084 32.7266C9.52769 32.749 9.45188 32.7928 9.39099 32.8537C9.33009 32.9146 9.28626 32.9904 9.26387 33.0736L8.82382 34.7078L8.38378 33.0736C8.36139 32.9904 8.31755 32.9146 8.25666 32.8537C8.19577 32.7928 8.11995 32.749 8.0368 32.7266L6.40253 32.2865L8.0368 31.8465C8.11995 31.8241 8.19577 31.7803 8.25666 31.7194C8.31755 31.6585 8.36139 31.5827 8.38378 31.4995L8.82382 29.8652L9.26387 31.4995C9.28626 31.5827 9.33009 31.6585 9.39098 31.7194C9.45188 31.7803 9.52769 31.8241 9.61084 31.8465Z'
          fill='#4F4FC8'
        />
        <path
          d='M13.3624 29.0141L14.3053 29.268L13.3624 29.5218C13.3145 29.5348 13.2707 29.5601 13.2356 29.5952C13.2005 29.6303 13.1752 29.6741 13.1623 29.722L12.9084 30.6649L12.6545 29.722C12.6416 29.6741 12.6163 29.6303 12.5812 29.5952C12.5461 29.5601 12.5023 29.5348 12.4544 29.5218L11.5115 29.268L12.4544 29.0141C12.5023 29.0012 12.5461 28.9759 12.5812 28.9408C12.6163 28.9057 12.6416 28.8619 12.6545 28.8139L12.9084 27.8711L13.1623 28.8139C13.1752 28.8619 13.2005 28.9057 13.2356 28.9408C13.2707 28.9759 13.3145 29.0012 13.3624 29.0141Z'
          fill='#4F4FC8'
        />
        <path
          d='M23.7926 37.0732V38.9587C23.7929 39.5786 23.5472 40.1733 23.1093 40.6122C22.6715 41.051 22.0773 41.298 21.4574 41.299C21.3686 41.2991 21.2799 41.294 21.1917 41.2837C20.6212 41.2176 20.0947 40.9443 19.7123 40.5157C19.3299 40.0872 19.1181 39.5331 19.1172 38.9587V37.0732H23.7926Z'
          fill='#7E98FD'
        />
        <path
          opacity='0.4'
          d='M23.7922 37.0733V38.9587C23.7926 39.5786 23.5469 40.1734 23.109 40.6122C22.6712 41.051 22.077 41.298 21.4571 41.299C21.3683 41.2991 21.2796 41.294 21.1914 41.2837C22.6681 40.3895 22.5608 38.1974 22.5608 38.1974C22.5608 38.1974 22.5353 38.1565 22.4995 38.085C22.339 37.7718 22.2549 37.4251 22.2542 37.0732L23.7922 37.0733Z'
          fill='#4F4FC8'
        />
        <path
          d='M25.2684 35.5297C25.2686 35.5644 25.2596 35.5985 25.2424 35.6285C25.228 35.6549 25.2106 35.6794 25.1905 35.7017C25.1674 35.7255 25.1426 35.7475 25.1163 35.7676C25.1092 35.7713 25.1029 35.7763 25.0977 35.7822C25.0643 35.8042 25.0235 35.8298 24.9789 35.8554C24.8789 35.9054 24.776 35.9494 24.6708 35.9872C24.5669 36.0238 24.4481 36.0567 24.3182 36.0896C24.1809 36.1226 24.0324 36.1555 23.8728 36.1848C23.0746 36.3203 22.266 36.3852 21.4564 36.3788C20.6456 36.3852 19.8357 36.3202 19.0363 36.1848C18.8767 36.1555 18.7282 36.1226 18.5909 36.0896C18.4981 36.0677 18.4127 36.0421 18.3347 36.0165C18.0735 35.9693 17.8371 35.832 17.6666 35.6285C17.6494 35.5985 17.6404 35.5644 17.6406 35.5297C17.6406 35.0613 19.3481 34.6807 21.4564 34.6807C23.561 34.6807 25.2684 35.0613 25.2684 35.5297Z'
          fill='#4F4FC8'
        />
        <path
          d='M25.2695 35.5303V37.2675C25.2695 37.5435 24.6921 37.7836 23.7928 37.942C23.3647 38.0127 22.9332 38.0605 22.5001 38.0851C22.1679 38.1055 21.8204 38.1158 21.4577 38.1158C20.6742 38.1205 19.8916 38.0624 19.1174 37.942C18.2181 37.7836 17.6407 37.5384 17.6407 37.2676V35.5303C17.6402 35.5424 17.642 35.5546 17.6458 35.5661C17.6503 35.5872 17.6572 35.6078 17.6662 35.6274C17.8363 35.832 18.0735 35.9697 18.3356 36.0157C18.4195 36.0439 18.5048 36.0678 18.5911 36.0873C18.7291 36.123 18.8773 36.1537 19.0356 36.1843C19.8356 36.3207 20.6462 36.3856 21.4577 36.3785C21.7847 36.3785 22.1015 36.3683 22.403 36.3479C22.8965 36.3255 23.3881 36.2709 23.8746 36.1843C24.033 36.1537 24.1811 36.123 24.3191 36.0873C24.5468 36.0357 24.7679 35.9585 24.9782 35.8573C25.0196 35.8354 25.059 35.8097 25.0958 35.7807C25.1035 35.7769 25.1104 35.7717 25.1162 35.7653C25.1691 35.7301 25.2129 35.6829 25.244 35.6274C25.253 35.6078 25.2599 35.5872 25.2644 35.5661C25.2683 35.5546 25.27 35.5424 25.2695 35.5303Z'
          fill='#7E98FD'
        />
        <path
          d='M26.1667 32.7969C26.1669 32.8397 26.1558 32.8818 26.1346 32.9189C26.1168 32.9515 26.0952 32.9818 26.0704 33.0093C26.0419 33.0387 26.0112 33.0659 25.9787 33.0907C25.97 33.0953 25.9622 33.1014 25.9557 33.1088C25.9145 33.1359 25.8641 33.1676 25.809 33.1992C25.6855 33.2609 25.5584 33.3153 25.4285 33.362C25.3001 33.4072 25.1533 33.4479 24.9929 33.4886C24.8232 33.5293 24.6398 33.5699 24.4426 33.6061C23.4566 33.7734 22.4578 33.8536 21.4577 33.8457C20.4561 33.8536 19.4558 33.7734 18.4682 33.6061C18.2711 33.5699 18.0876 33.5292 17.918 33.4885C17.8034 33.4614 17.6979 33.4298 17.6016 33.3981C17.2789 33.3399 16.9869 33.1703 16.7763 32.9189C16.755 32.8818 16.744 32.8397 16.7442 32.7969C16.7442 32.2182 18.8534 31.748 21.4577 31.748C24.0575 31.7481 26.1667 32.2182 26.1667 32.7969Z'
          fill='#4F4FC8'
        />
        <path
          d='M26.1696 32.7969V34.943C26.1696 35.1729 25.8375 35.3926 25.2703 35.5664H25.2652C24.4023 35.7975 23.5162 35.9312 22.6235 35.9649C22.2505 35.9853 21.8621 35.9956 21.4584 35.9955C20.1743 36.0239 18.8923 35.8796 17.6466 35.5663H17.6415C17.0743 35.3926 16.7422 35.1729 16.7422 34.9429V32.7969C16.7421 32.8403 16.7545 32.8829 16.778 32.9195C16.9887 33.1696 17.2792 33.3393 17.6006 33.3998C17.6977 33.4305 17.805 33.4612 17.9174 33.4867C18.086 33.5276 18.27 33.5685 18.4693 33.6042C19.4564 33.7739 20.4569 33.8543 21.4584 33.8444C21.7803 33.8444 22.0971 33.8393 22.4037 33.8239C23.0874 33.7958 23.7685 33.7224 24.4425 33.6042C24.6418 33.5685 24.8258 33.5276 24.9944 33.4867C25.1528 33.4458 25.3009 33.4049 25.4287 33.3641C25.5769 33.3079 25.7046 33.2516 25.8119 33.2006C25.863 33.1699 25.9141 33.1341 25.955 33.1086C25.9652 33.0984 25.9703 33.0933 25.9806 33.0933C26.0164 33.0626 26.0419 33.0371 26.0725 33.0115C26.0959 32.9829 26.1164 32.9521 26.1339 32.9195C26.1573 32.8829 26.1697 32.8403 26.1696 32.7969Z'
          fill='#7E98FD'
        />
        <path
          d='M8.76921 13.6182L0.160165 11.166L0.0546875 14.0578L8.76921 13.6182Z'
          fill='#FF635F'
        />
        <path d='M8.95246 11.5792L6.21053 6.41797L4.05469 7.9357L8.95246 11.5792Z' fill='#FF635F' />
        <path
          d='M8.61002 15.7158L3.19727 17.1658L4.75604 19.1016L8.61002 15.7158Z'
          fill='#FF635F'
        />
        <path
          d='M34.2832 12.5676L41.9878 11.9409L41.1965 9.57031L34.2832 12.5676Z'
          fill='#FF635F'
        />
        <path
          d='M34.7539 14.2725L38.5358 17.6148L39.8192 15.7343L34.7539 14.2725Z'
          fill='#FF635F'
        />
        <path d='M33.7773 10.823L37.7144 8.00995L35.8673 6.91699L33.7773 10.823Z' fill='#FF635F' />
        <path
          d='M26.7057 30.4208C26.7059 30.4691 26.6936 30.5167 26.6699 30.5587C26.6501 30.5955 26.6261 30.6298 26.5983 30.6609C26.5666 30.6941 26.5325 30.7248 26.4962 30.7529C26.4865 30.7581 26.4778 30.765 26.4706 30.7734C26.4246 30.804 26.3684 30.8398 26.3071 30.8756C26.1695 30.9453 26.0279 31.0067 25.883 31.0595C25.7399 31.1106 25.5764 31.1566 25.3976 31.2026C25.2085 31.2486 25.0041 31.2945 24.7844 31.3354C23.6859 31.5246 22.5726 31.6152 21.458 31.6063C20.3416 31.6152 19.2267 31.5245 18.1265 31.3354C17.9067 31.2945 17.7023 31.2486 17.5133 31.2026C17.3855 31.1719 17.268 31.1361 17.1607 31.1004C16.7999 31.0337 16.4742 30.8419 16.241 30.5587C16.2172 30.5167 16.2049 30.4691 16.2052 30.4208C16.2052 29.7668 18.5556 29.2354 21.4579 29.2354C24.3552 29.2354 26.7057 29.7668 26.7057 30.4208Z'
          fill='#4F4FC8'
        />
        <path
          d='M26.7057 30.4209V32.2553C26.7057 32.8582 24.7231 33.3539 22.1529 33.4305C21.9281 33.4356 21.693 33.4407 21.458 33.4407C18.5557 33.4407 16.2052 32.9093 16.2052 32.2553V30.4209C16.2049 30.4692 16.2173 30.5167 16.241 30.5588C16.4743 30.842 16.8 31.0338 17.1607 31.1005C17.2681 31.1362 17.3856 31.172 17.5133 31.2027C17.7024 31.2487 17.9068 31.2946 18.1265 31.3355C19.2267 31.5246 20.3417 31.6153 21.458 31.6063C21.8515 31.6063 22.2347 31.5961 22.6026 31.5757C23.3344 31.5443 24.0633 31.4641 24.7844 31.3355C25.0041 31.2946 25.2085 31.2487 25.3976 31.2027C25.5764 31.1567 25.7399 31.1107 25.883 31.0596C26.0279 31.0068 26.1695 30.9454 26.3071 30.8757C26.3685 30.8399 26.4247 30.8041 26.4706 30.7735C26.4778 30.7651 26.4865 30.7582 26.4962 30.753C26.5325 30.7249 26.5666 30.6942 26.5984 30.661C26.6261 30.6299 26.6501 30.5956 26.6699 30.5588C26.6936 30.5167 26.7059 30.4692 26.7057 30.4209Z'
          fill='#7E98FD'
        />
        <path
          d='M33.2914 16.0368C33.2901 17.837 32.8758 19.6129 32.0803 21.2278C31.2848 22.8427 30.1293 24.2536 28.7029 25.3518C28.1303 25.7875 27.5218 26.1738 26.8838 26.5066C26.5818 26.6666 26.3295 26.9067 26.1547 27.2005C25.9798 27.4943 25.8892 27.8305 25.8925 28.1723V29.6695C25.8929 29.8177 25.859 29.9641 25.7934 30.0971C25.7278 30.23 25.6323 30.346 25.5144 30.4359C25.3939 30.5307 25.2516 30.5939 25.1005 30.6199L24.9728 30.6454C23.8351 30.8554 22.6807 30.9614 21.5237 30.9622H21.5136C21.3552 30.9622 21.2018 30.9622 21.0486 30.9571C20.0057 30.9308 18.9667 30.8197 17.9418 30.625C17.7831 30.5947 17.634 30.5264 17.5075 30.4257V30.4206C17.3978 30.3296 17.3093 30.2156 17.2484 30.0867C17.1875 29.9578 17.1556 29.8172 17.1549 29.6746V28.1877C17.1522 27.8309 17.051 27.4819 16.8626 27.1789C16.6742 26.876 16.4058 26.631 16.087 26.4708C13.9897 25.3787 12.2699 23.6803 11.1516 21.5968C10.0333 19.5134 9.56841 17.1414 9.81741 14.79C9.8166 14.7795 9.81836 14.769 9.82252 14.7593C10.1483 11.7657 11.6093 9.01085 13.9046 7.0617C16.2 5.11256 19.1552 4.11742 22.1621 4.28108C25.1689 4.44475 27.9987 5.75476 30.0689 7.94154C32.1392 10.1283 33.2925 13.0255 33.2914 16.0368Z'
          fill='#FFB7AB'
        />
        <path
          d='M19.9548 30.3399V20.9903C19.9548 20.5907 19.7961 20.2074 19.5135 19.9248C19.2309 19.6422 18.8476 19.4834 18.4479 19.4834H17.4014C17.2036 19.4834 17.0076 19.5224 16.8248 19.5981C16.6419 19.6738 16.4758 19.7848 16.3359 19.9248C16.196 20.0647 16.085 20.2308 16.0092 20.4136C15.9335 20.5965 15.8945 20.7924 15.8945 20.9903C15.8945 21.1882 15.9335 21.3842 16.0092 21.567C16.085 21.7498 16.196 21.9159 16.3359 22.0559C16.4758 22.1958 16.6419 22.3068 16.8248 22.3825C17.0076 22.4583 17.2036 22.4972 17.4014 22.4972H25.6477C25.8456 22.4972 26.0415 22.4583 26.2243 22.3825C26.4072 22.3068 26.5733 22.1958 26.7132 22.0559C26.8531 21.9159 26.9641 21.7498 27.0399 21.567C27.1156 21.3842 27.1546 21.1882 27.1546 20.9903C27.1546 20.7924 27.1156 20.5965 27.0399 20.4136C26.9641 20.2308 26.8531 20.0647 26.7132 19.9248C26.5733 19.7848 26.4072 19.6738 26.2243 19.5981C26.0415 19.5224 25.8456 19.4834 25.6477 19.4834H24.6331C24.4352 19.4834 24.2393 19.5224 24.0565 19.5981C23.8736 19.6738 23.7075 19.7848 23.5676 19.9248C23.4276 20.0647 23.3167 20.2308 23.2409 20.4136C23.1652 20.5965 23.1262 20.7924 23.1262 20.9903V30.3399'
          stroke='#FF635F'
          strokeWidth='0.75'
          strokeMiterlimit='10'
        />
        <path
          opacity='0.4'
          d='M25.5244 30.3951C25.5234 30.4092 25.52 30.423 25.5142 30.436C25.3936 30.5307 25.2514 30.5939 25.1003 30.6199L24.9725 30.6455C23.8348 30.8554 22.6804 30.9614 21.5235 30.9623H21.5133C21.3549 30.9623 21.2016 30.9623 21.0483 30.9572C20.0054 30.9308 18.9664 30.8197 17.9416 30.625C17.7828 30.5948 17.6337 30.5264 17.5073 30.4258V30.4206C17.5031 30.4128 17.5013 30.4039 17.5021 30.3951C17.5021 30.0834 19.3008 29.833 21.5133 29.833C23.7309 29.833 25.5244 30.0834 25.5244 30.3951Z'
          fill='#FF635F'
        />
        <path
          opacity='0.4'
          d='M26.706 30.4209V32.2553C26.706 32.8582 24.7234 33.3539 22.1532 33.4305C21.5503 32.5874 22.6029 31.5757 22.6029 31.5757C23.3347 31.5443 24.0636 31.4641 24.7847 31.3355C25.0044 31.2946 25.2088 31.2487 25.3979 31.2027C25.5767 31.1567 25.7402 31.1107 25.8833 31.0596C26.0282 31.0068 26.1698 30.9454 26.3074 30.8757C26.3687 30.8399 26.4249 30.8041 26.4709 30.7735C26.4781 30.7651 26.4868 30.7582 26.4965 30.753C26.5328 30.7249 26.5669 30.6942 26.5987 30.661C26.6264 30.6299 26.6504 30.5956 26.6702 30.5588C26.6939 30.5167 26.7062 30.4692 26.706 30.4209Z'
          fill='#4F4FC8'
        />
        <path
          opacity='0.4'
          d='M26.1682 32.7969V34.943C26.1682 35.1729 25.836 35.3926 25.2689 35.5664H25.2638C24.4008 35.7975 23.5147 35.9312 22.6221 35.9649C23.1688 35.1525 22.6731 34.2225 22.4023 33.8239C23.086 33.7958 23.7671 33.7224 24.4411 33.6042C24.6404 33.5684 24.8244 33.5276 24.993 33.4867C25.1514 33.4458 25.2995 33.4049 25.4273 33.3641C25.5755 33.3078 25.7032 33.2516 25.8105 33.2005C25.8616 33.1699 25.9127 33.1341 25.9536 33.1086C25.9638 33.0983 25.9689 33.0932 25.9792 33.0932C26.015 33.0626 26.0405 33.037 26.0711 33.0115C26.0945 32.9829 26.115 32.9521 26.1325 32.9195C26.1559 32.8829 26.1683 32.8403 26.1682 32.7969Z'
          fill='#4F4FC8'
        />
        <path
          opacity='0.4'
          d='M25.2706 35.5303V37.2675C25.2706 37.5435 24.6932 37.7836 23.7939 37.942C23.3658 38.0127 22.9343 38.0605 22.5012 38.0851C22.3407 37.772 22.2566 37.4253 22.2559 37.0734C22.2552 36.8239 22.3056 36.577 22.4041 36.3478C22.8976 36.3254 23.3892 36.2708 23.8757 36.1843C24.0341 36.1536 24.1822 36.123 24.3202 36.0872C24.5479 36.0356 24.769 35.9585 24.9794 35.8573C25.0207 35.8353 25.0601 35.8097 25.0969 35.7806C25.1046 35.7768 25.1115 35.7716 25.1173 35.7653C25.1702 35.7301 25.214 35.6828 25.2451 35.6274C25.2541 35.6077 25.261 35.5872 25.2655 35.566C25.2694 35.5545 25.2711 35.5424 25.2706 35.5303Z'
          fill='#4F4FC8'
        />
        <path
          opacity='0.4'
          d='M31.6666 21.9951C30.9035 23.2942 29.8973 24.434 28.7029 25.3522C28.1303 25.7879 27.5218 26.1742 26.8838 26.507C26.5817 26.6671 26.3295 26.9071 26.1547 27.2009C25.9798 27.4947 25.8891 27.8309 25.8925 28.1728V29.6699C25.8929 29.8182 25.859 29.9645 25.7934 30.0975C25.7278 30.2305 25.6323 30.3464 25.5144 30.4364C25.3939 30.5311 25.2516 30.5943 25.1005 30.6203L24.9728 30.6459C23.8351 30.8558 22.6807 30.9619 21.5237 30.9627H21.5136C21.3552 30.9627 21.2018 30.9627 21.0485 30.9576C20.0057 30.9312 18.9667 30.8201 17.9418 30.6254C17.7831 30.5952 17.634 30.5268 17.5075 30.4262V30.421C17.3978 30.33 17.3093 30.2161 17.2484 30.0872C17.1875 29.9583 17.1556 29.8176 17.1549 29.675V28.1881C17.1522 27.8314 17.051 27.4823 16.8626 27.1794C16.6742 26.8764 16.4058 26.6314 16.087 26.4712C13.9897 25.3791 12.2699 23.6807 11.1516 21.5973C10.0333 19.5138 9.56841 17.1419 9.81741 14.7904C9.8166 14.7799 9.81836 14.7694 9.82252 14.7598C10.8649 17.1205 14.6001 24.2996 21.5238 24.3405C26.9145 24.3763 29.2803 23.7273 31.6666 21.9951Z'
          fill='#FF635F'
        />
        <path
          opacity='0.2'
          d='M27.22 7.72371C27.22 7.72371 23.978 5.49712 23.6136 7.53816C23.2492 9.5792 26.3293 13.2361 28.4075 13.3188C30.4856 13.4015 29.2239 9.65342 27.22 7.72371Z'
          fill='white'
        />
      </g>
    </>
  );
};
