import React from 'react';

export const DigitalAds = ({ className }) => {
  return (
    <>
      <g className={className}>
        <path
          d='M42.4144 5.72109L6.7304 0.380359C5.71874 0.228946 4.77898 0.947586 4.63139 1.98549L3.36808 10.8697C3.22049 11.9076 3.92097 12.8718 4.93263 13.0232L40.6166 18.3639C41.6283 18.5153 42.5681 17.7967 42.7156 16.7588L43.979 7.87453C44.1265 6.83663 43.4261 5.8725 42.4144 5.72109Z'
          fill='#FFB3A8'
        />
        <path
          d='M40.0644 5.3605L4.3804 0.0197635C3.36873 -0.13165 2.42897 0.58699 2.28139 1.62489L1.01807 10.5091C0.870489 11.547 1.57096 12.5112 2.58263 12.6626L38.2666 18.0033C39.2783 18.1547 40.2181 17.4361 40.3656 16.3982L41.629 7.51394C41.7765 6.47604 41.0761 5.51191 40.0644 5.3605Z'
          fill='#FF635F'
        />
        <path
          d='M9.6975 7.01048C9.75006 6.63847 9.86567 6.28397 10.0457 5.94565C10.2243 5.60733 10.4595 5.30945 10.7486 5.052C11.0376 4.79455 11.3726 4.60585 11.751 4.48319C12.1294 4.36188 12.5445 4.33492 12.9965 4.40232C13.5365 4.48319 13.9832 4.67729 14.3353 4.98326C14.6874 5.28923 14.9304 5.65181 15.0645 6.06831L14.2565 6.46459C14.1855 6.2031 14.0751 5.98743 13.9254 5.81895C13.7756 5.65046 13.6048 5.52241 13.4117 5.43615C13.2199 5.34853 13.028 5.29058 12.8388 5.26227C12.5327 5.21644 12.2529 5.2407 12.0006 5.33371C11.7484 5.42671 11.5277 5.56824 11.3385 5.7556C11.1493 5.94295 10.9969 6.16266 10.8826 6.41472C10.767 6.66542 10.6908 6.92287 10.654 7.18571C10.6119 7.48359 10.6172 7.77339 10.671 8.0551C10.7249 8.33816 10.8221 8.5956 10.9627 8.83014C11.1033 9.06467 11.2807 9.25876 11.4948 9.41377C11.709 9.56878 11.9546 9.66718 12.2345 9.70896C12.4289 9.73861 12.6352 9.73322 12.8507 9.69548C13.0661 9.65774 13.2724 9.57821 13.4695 9.45556C13.6666 9.3329 13.8308 9.16576 13.9635 8.95145L14.6769 9.51352C14.5166 9.79927 14.2919 10.0284 14.0029 10.2009C13.7138 10.3735 13.3998 10.4907 13.0609 10.55C12.7219 10.6107 12.3935 10.6161 12.0768 10.5689C11.6695 10.5083 11.3069 10.3613 10.9864 10.1295C10.6658 9.89766 10.4004 9.61326 10.1902 9.27494C9.97866 8.93662 9.8302 8.56999 9.7448 8.17506C9.65809 7.78013 9.64232 7.39059 9.6975 7.00779V7.01048Z'
          fill='white'
        />
        <path
          d='M23.4215 6.87295L21.4482 6.57776L20.7032 11.817L19.7586 11.6755L20.5035 6.43623L18.5223 6.1397L18.6432 5.29053L23.5411 6.02378L23.4202 6.87295H23.4215Z'
          fill='white'
        />
        <path
          d='M28.3484 6.74341L29.2339 6.8755L30.6751 13.309L29.6885 13.1621L29.3442 11.4206L26.9715 11.0661L26.1543 12.6337L25.1676 12.4868L28.3484 6.74476V6.74341ZM29.1971 10.6712L28.6413 7.86485L27.3236 10.3908L29.1971 10.6712Z'
          fill='white'
        />
        <path
          d='M16.9169 22.3279C16.9169 22.3279 17.4647 31.2698 38.7237 34.5263L27.4813 40.0001C27.4813 40.0001 12.2292 35.9241 15.427 22.8428L16.9182 22.3279H16.9169Z'
          fill='url(#paint0_linear_3544_56392)'
        />
        <path
          d='M11.6117 22.6891L15.1892 19.8976L14.711 25.7812L16.7566 25.4604L16.9918 19.69L21.129 22.0542L16.3703 11.8184L11.6117 22.6891Z'
          fill='#FFB7AB'
        />
        <path
          d='M11.6117 22.6889L12.8309 23.371L15.0539 21.5702L15.1892 19.8975L11.6117 22.6889Z'
          fill='#FF635F'
        />
        <path
          d='M11.6117 22.6889L12.8309 23.371L15.0539 21.5702L15.1892 19.8975L11.6117 22.6889Z'
          fill='url(#paint1_linear_3544_56392)'
        />
        <path
          d='M14.7109 25.7812L15.4651 26.6573L17.8667 26.265L17.939 21.2913L22.9841 24.1475L17.7564 12.9385L16.3703 11.8184L21.1289 22.0542L16.9917 19.69L16.7566 25.4604L14.7109 25.7812Z'
          fill='#FF635F'
        />
        <path
          d='M14.7109 25.7812L15.4651 26.6573L17.8667 26.265L17.939 21.2913L22.9841 24.1475L17.7564 12.9385L16.3703 11.8184L21.1289 22.0542L16.9917 19.69L16.7566 25.4604L14.7109 25.7812Z'
          fill='url(#paint2_linear_3544_56392)'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_3544_56392'
          x1='16.2679'
          y1='21.9788'
          x2='34.0555'
          y2='39.3168'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#4F4FC8' />
          <stop offset='0.1' stopColor='#4F4FC8' stopOpacity='0.82' />
          <stop offset='0.27' stopColor='#4F4FC8' stopOpacity='0.57' />
          <stop offset='0.43' stopColor='#4F4FC8' stopOpacity='0.37' />
          <stop offset='0.58' stopColor='#4F4FC8' stopOpacity='0.21' />
          <stop offset='0.73' stopColor='#4F4FC8' stopOpacity='0.09' />
          <stop offset='0.88' stopColor='#4F4FC8' stopOpacity='0.02' />
          <stop offset='1' stopColor='#4F4FC8' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_3544_56392'
          x1='11.6117'
          y1='21.6349'
          x2='15.1892'
          y2='21.6349'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#4F4FC8' />
          <stop offset='1' stopColor='#FF635F' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_3544_56392'
          x1='14.7109'
          y1='19.2371'
          x2='22.9841'
          y2='19.2371'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF635F' />
          <stop offset='1' stopColor='#4F4FC8' />
        </linearGradient>
      </defs>
    </>
  );
};
