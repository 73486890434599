import React from 'react';

export const Illustration = ({ className }) => {
  return (
    <>
      <g className={className}>
        <path
          d='M9.28316 16.3552C9.28316 16.3552 5.76118 17.2123 3.4237 14.5113C1.08747 11.8103 -2.44459 7.30897 2.4887 0C2.4887 0 1.91788 4.23658 7.16241 6.56753C12.4082 8.8972 11.6282 13.8225 11.6282 13.8225L9.2819 16.3552H9.28316Z'
          fill='#FFB2A7'
        />
        <path
          d='M9.28316 16.3552C9.28316 16.3552 5.76118 17.2123 3.4237 14.5113C1.08747 11.8103 -2.44459 7.30897 2.4887 0C2.4887 0 1.91788 4.23658 7.16241 6.56753C12.4082 8.8972 11.6282 13.8225 11.6282 13.8225L9.2819 16.3552H9.28316Z'
          fill='url(#paint0_linear_3544_56626)'
        />
        <path
          d='M9.28294 16.3551C9.28294 16.3551 9.14432 14.6551 11.6456 13.6631C11.6456 13.6631 27.4574 24.6946 31.3485 28.6627C35.2397 32.6307 43.4694 42.5713 41.9409 44.6979C40.4124 46.8233 27.8971 37.2103 24.1534 33.2192C21.3169 30.1956 9.2842 16.3551 9.2842 16.3551H9.28294Z'
          fill='#7272D8'
        />
        <path
          d='M9.28294 16.3551C9.28294 16.3551 9.14432 14.6551 11.6456 13.6631C11.6456 13.6631 27.4574 24.6946 31.3485 28.6627C35.2397 32.6307 43.4694 42.5713 41.9409 44.6979C40.4124 46.8233 27.8971 37.2103 24.1534 33.2192C21.3169 30.1956 9.2842 16.3551 9.2842 16.3551H9.28294Z'
          fill='url(#paint1_linear_3544_56626)'
        />
        <path
          d='M22.5075 23.0978C22.3701 22.8113 22.1849 22.5504 21.9656 22.3178C22.1521 22.0557 22.3235 21.791 22.4671 21.525C22.4747 21.5109 22.4772 21.4967 22.481 21.4813C22.4054 21.4248 22.3298 21.3682 22.2542 21.3117C22.2302 21.3258 22.2088 21.3438 22.1949 21.3695C22.0626 21.6124 21.9076 21.8552 21.7375 22.0968C20.955 21.4068 19.8386 21.0444 18.7385 21.1768C19.0119 20.6165 19.269 20.0473 19.5072 19.4729C19.5399 19.3919 19.5034 19.2981 19.424 19.2647C19.3446 19.23 19.2526 19.2686 19.2199 19.3495C18.9552 19.9895 18.6667 20.6217 18.3567 21.2436C17.2226 21.498 16.1477 22.2472 15.3564 23.3137C15.4269 23.3946 15.4975 23.4743 15.5681 23.5553C16.2586 22.6031 17.1797 21.9144 18.1639 21.6214C17.8564 22.2189 17.5301 22.8048 17.1835 23.3767L17.1155 23.4885C16.8937 23.8534 16.6669 24.2273 16.5119 24.6334C16.5913 24.7233 16.6694 24.8133 16.7488 24.9032C16.8899 24.465 17.1382 24.0538 17.3801 23.6555L17.4482 23.5424C17.8451 22.8858 18.2181 22.2112 18.5659 21.5224C19.6546 21.3233 20.7849 21.6638 21.5498 22.35C20.5115 23.7249 18.9741 25.0343 17.7279 26.0173C17.7972 26.097 17.8678 26.1753 17.9371 26.255C19.1858 25.2694 20.7206 23.96 21.7766 22.5723C21.9568 22.7714 22.1093 22.9937 22.2252 23.2353C22.8338 24.5036 22.4495 26.0764 22.108 27.1494C21.8182 28.0591 21.4666 28.9586 21.105 29.8414C21.1831 29.9288 21.26 30.0161 21.3368 30.1022C21.7211 29.1706 22.0979 28.2172 22.4054 27.2483C22.7633 26.1227 23.164 24.4689 22.5062 23.0965L22.5075 23.0978Z'
          fill='#4F4FCE'
        />
        <path
          d='M26.0726 34.2079C26.7594 31.8769 27.6994 28.6837 28.836 26.4749C28.8436 26.4607 28.8461 26.4453 28.8486 26.4312C28.7793 26.3734 28.7088 26.3155 28.6369 26.2564C28.6054 26.2706 28.5765 26.2924 28.5588 26.3271C27.4083 28.563 26.4633 31.7728 25.7727 34.1166C25.7526 34.1847 25.6959 34.3595 25.6089 34.4584C25.624 33.1915 25.9189 31.1496 26.2062 29.172C26.4481 27.5003 26.6787 25.9056 26.7531 24.744C26.5842 24.6104 26.4129 24.4767 26.2402 24.3418C25.6694 25.1693 24.9827 26.5777 24.3148 27.9475C23.6784 29.253 23.0245 30.5945 22.4902 31.3925C22.5633 31.4747 22.6363 31.5544 22.7069 31.6341C23.2576 30.8322 23.909 29.4946 24.5945 28.0888C25.241 26.7627 25.905 25.3993 26.4507 24.5885C26.3851 25.7604 26.1382 27.4669 25.8987 29.1245C25.6177 31.0661 25.3279 33.0668 25.3015 34.3608C25.4413 34.4931 25.5862 34.6281 25.7362 34.7668C25.8584 34.682 25.9832 34.5176 26.0739 34.2079H26.0726Z'
          fill='#4F4FCE'
        />
        <path
          d='M32.4775 38.2813C32.5229 38.253 32.5683 38.226 32.6149 38.1952C33.5096 37.6079 34.4609 36.3435 35.0633 35.0624C35.5371 34.8491 36.0272 34.6962 36.4758 34.6255C36.5048 34.6204 36.5288 34.6062 36.5489 34.5895C36.4784 34.5021 36.4091 34.4148 36.3385 34.3287C35.9894 34.3904 35.619 34.4957 35.2485 34.6396C35.4161 34.2259 35.5408 33.8198 35.6114 33.4408C35.5345 33.3482 35.4589 33.257 35.3833 33.1658C35.3556 33.1889 35.3355 33.221 35.3292 33.2609C35.2535 33.744 35.0771 34.2798 34.8352 34.817C33.8926 35.259 33.0194 35.94 32.6376 36.7701C32.4738 37.1248 32.3213 37.572 32.2054 38.0757C31.8097 38.3005 31.4455 38.4226 31.0965 38.4419C30.5924 38.4702 30.1275 38.2684 29.8805 37.9138C29.8024 37.802 29.7482 37.676 29.7078 37.5424C30.9541 35.4505 32.5784 33.5975 34.4849 32.1057C34.4168 32.026 34.3475 31.9463 34.2795 31.8679C32.4423 33.3084 30.8608 35.0778 29.6234 37.0734C29.6121 36.932 29.6083 36.7881 29.6096 36.6455C29.6196 34.6345 30.7449 30.7834 31.6698 28.9947C31.5942 28.9163 31.5199 28.8392 31.4468 28.7634C31.4354 28.7737 31.4254 28.7853 31.4178 28.7994C30.4664 30.6112 29.3071 34.5137 29.2971 36.6429C29.2958 36.9269 29.3084 37.2173 29.3714 37.4923C29.321 37.5784 29.2731 37.6645 29.224 37.7519C29.3059 37.8174 29.389 37.8842 29.4709 37.9497C29.4823 37.9279 29.4936 37.9073 29.5062 37.8855C29.534 37.942 29.5642 37.9973 29.5982 38.0513C29.8439 38.2466 30.0922 38.4432 30.3442 38.6385C30.5483 38.7169 30.7726 38.7606 31.0057 38.7606C31.0423 38.7606 31.0788 38.7606 31.1154 38.758C31.4405 38.74 31.7744 38.6449 32.1247 38.4753C32.0453 38.9225 31.9987 39.4056 32.0025 39.9016C32.1083 39.98 32.2142 40.0584 32.32 40.1355C32.2923 39.4814 32.3591 38.8467 32.4788 38.2813H32.4775ZM32.9198 36.9038C33.2134 36.2651 33.8599 35.7036 34.6033 35.2924C34.0653 36.3217 33.3155 37.2918 32.5884 37.8251C32.6855 37.4769 32.8001 37.1646 32.9198 36.9038Z'
          fill='#4F4FCE'
        />
        <path
          d='M38.5036 37.1319C38.4419 37.0471 38.3789 36.9623 38.3159 36.8774C36.8391 38.1239 35.6646 40.3559 35.4529 42.3116C35.5525 42.3759 35.652 42.4401 35.7503 42.5031C35.9041 40.5923 37.0482 38.3642 38.5036 37.1319Z'
          fill='#4F4FCE'
        />
        <path
          d='M38.6173 41.9995C38.6715 41.9275 38.7219 41.8607 38.7673 41.8003C39.2915 41.1167 39.827 40.4125 40.2114 39.6197C40.1458 39.5169 40.0803 39.4141 40.0135 39.3113C39.6393 40.1452 39.0722 40.8867 38.5216 41.605C38.475 41.6666 38.4233 41.7348 38.3691 41.8067C38.015 42.2783 37.4807 42.9915 37.0347 43.1855C37.0019 43.1996 36.9792 43.2253 36.9628 43.2536C37.0624 43.3127 37.1619 43.3705 37.2602 43.4271C37.7491 43.1572 38.2481 42.4942 38.6173 42.0007V41.9995Z'
          fill='#4F4FCE'
        />
        <path
          d='M40.2235 42.8378C40.1214 43.7425 40.6771 44.6561 41.4647 44.8745C41.4786 44.8784 41.4924 44.8797 41.5063 44.8797C41.5743 44.8797 41.6373 44.8334 41.6562 44.7627C41.6789 44.6779 41.6298 44.5905 41.5466 44.5674C40.9027 44.3888 40.4478 43.6294 40.5335 42.8738C40.5915 42.3637 40.8649 41.8677 41.2896 41.4758C41.2404 41.3807 41.1913 41.2856 41.1383 41.1892C40.6255 41.639 40.2928 42.2275 40.2235 42.8378Z'
          fill='#4F4FCE'
        />
        <path
          d='M16.0074 16.7547C15.9015 16.6789 15.7957 16.6031 15.6923 16.5286C15.7314 18.226 15.3949 19.9183 14.7031 21.468C14.6049 21.6878 14.4839 21.9293 14.3276 22.1362C14.3969 22.2159 14.4675 22.2956 14.5381 22.3765C14.7296 22.1349 14.8733 21.8535 14.9867 21.5991C15.6659 20.0764 16.0124 18.4201 16.0074 16.7534V16.7547Z'
          fill='#4F4FCE'
        />
        <path
          d='M14.6422 15.7794C14.5477 15.7126 14.4545 15.6458 14.3637 15.5815C12.8088 16.5877 12.146 17.9253 11.6255 19.0446C11.6999 19.1294 11.7742 19.2155 11.8511 19.3028C12.4018 18.113 13.0268 16.7753 14.6422 15.7794Z'
          fill='#4F4FCE'
        />
        <path
          d='M12.868 14.5216C12.7344 14.4278 12.6109 14.3404 12.4975 14.2607C11.6885 14.4483 10.911 15.0574 10.3427 15.9852C10.1449 16.309 9.9798 16.6495 9.84497 17.0016C9.9168 17.0851 9.99492 17.1738 10.0793 17.2715C10.2142 16.8821 10.3906 16.5082 10.6073 16.1535C11.0723 15.3928 11.8964 14.5948 12.868 14.5216Z'
          fill='#4F4FCE'
        />
        <path
          d='M2.20653 13.013C0.147524 10.2387 -1.62544 6.09594 2.48879 0C2.48879 0 1.91797 4.23658 7.1625 6.56753C7.1625 6.56753 5.60124 6.64078 4.88424 7.58395C3.83584 8.96145 5.67684 11.2667 4.64104 12.4103C3.80559 13.333 2.20527 13.013 2.20527 13.013H2.20653Z'
          fill='#5CFF85'
        />
        <path
          d='M2.20653 13.013C0.147524 10.2387 -1.62544 6.09594 2.48879 0C2.48879 0 1.91797 4.23658 7.1625 6.56753C7.1625 6.56753 5.60124 6.64078 4.88424 7.58395C3.83584 8.96145 5.67684 11.2667 4.64104 12.4103C3.80559 13.333 2.20527 13.013 2.20527 13.013H2.20653Z'
          fill='url(#paint2_linear_3544_56626)'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_3544_56626'
          x1='2.21148'
          y1='6.2797'
          x2='10.0426'
          y2='15.1062'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF5457' stopOpacity='0' />
          <stop offset='0.26' stopColor='#FF5457' stopOpacity='0' />
          <stop offset='0.39' stopColor='#FF5659' stopOpacity='0.04' />
          <stop offset='0.5' stopColor='#FF5A5D' stopOpacity='0.09' />
          <stop offset='0.6' stopColor='#FF5F62' stopOpacity='0.17' />
          <stop offset='0.68' stopColor='#FF6668' stopOpacity='0.27' />
          <stop offset='0.76' stopColor='#FF6F70' stopOpacity='0.39' />
          <stop offset='0.83' stopColor='#FF797A' stopOpacity='0.53' />
          <stop offset='0.9' stopColor='#FF8485' stopOpacity='0.7' />
          <stop offset='0.96' stopColor='#FF9192' stopOpacity='0.88' />
          <stop offset='1' stopColor='#FF9A9A' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_3544_56626'
          x1='9.28167'
          y1='29.3308'
          x2='42.1274'
          y2='29.3308'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#93ADFF' />
          <stop offset='0.1' stopColor='#93ADFF' stopOpacity='0.82' />
          <stop offset='0.23' stopColor='#93ADFF' stopOpacity='0.6' />
          <stop offset='0.37' stopColor='#93ADFF' stopOpacity='0.42' />
          <stop offset='0.5' stopColor='#93ADFF' stopOpacity='0.27' />
          <stop offset='0.63' stopColor='#93ADFF' stopOpacity='0.15' />
          <stop offset='0.76' stopColor='#93ADFF' stopOpacity='0.07' />
          <stop offset='0.88' stopColor='#93ADFF' stopOpacity='0.02' />
          <stop offset='1' stopColor='#93ADFF' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_3544_56626'
          x1='1.78943'
          y1='5.33267'
          x2='5.83425'
          y2='11.3624'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#93ADFF' stopOpacity='0' />
          <stop offset='1' stopColor='#0093AB' />
        </linearGradient>
      </defs>
    </>
  );
};
