import React from 'react';
import { cn } from '@superside/ui';

export const PrismTriangle = () => {
  return (
    <>
      <g>
        <g className={'768:scale-100 <768:[transform:scale(0.4)_translate(30px,20px)]'}>
          <path
            d='M499.629 171.141L308.623 612.07H779.771L499.629 171.141Z'
            fill='url(#paint14_radial_38_451)'
          />
          <path
            d='M499.629 171.141L228.097 612.07H308.623L499.629 171.141Z'
            fill='url(#paint15_radial_38_451)'
          />
        </g>
        <text
          className={
            '768:text-[56px] 768:translate-x-[448px] 768:translate-y-[419px] translate-x-48 translate-y-[169px] fill-green-500 text-[23px] font-bold'
          }
        >
          CaaS
        </text>
        <text
          className={cn(
            '768:text-base fill-light tracking-15 text-bor-xs font-semibold uppercase',
            '768:translate-x-[452px] 768:translate-y-[455px] translate-x-44 translate-y-[189px]'
          )}
        >
          Creative-as
        </text>
        <text
          className={cn(
            '768:text-base fill-light tracking-15 text-bor-xs font-semibold uppercase',
            '768:translate-x-[465px] 768:translate-y-[478px] translate-x-46 translate-y-[206px]'
          )}
        >
          a-service
        </text>
      </g>
      <defs>
        <radialGradient
          id='paint14_radial_38_451'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(532.834 427.024) rotate(-10.0728) scale(228.016 292.217)'
        >
          <stop stopColor='#0B253A' />
          <stop offset='0.14' stopColor='#0D2940' />
          <stop offset='0.34' stopColor='#133554' />
          <stop offset='0.58' stopColor='#1D4973' />
          <stop offset='0.61' stopColor='#1F4C77' />
          <stop offset='0.82' stopColor='#0C5F95' />
          <stop offset='1' stopColor='#006DAA' />
        </radialGradient>
        <radialGradient
          id='paint15_radial_38_451'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(382.147 390.016) rotate(27.9808) scale(31.5585 237.492)'
        >
          <stop stopColor='#1F4C77' />
          <stop offset='0.18' stopColor='#204F7B' />
          <stop offset='0.38' stopColor='#255889' />
          <stop offset='0.6' stopColor='#2C67A0' />
          <stop offset='0.68' stopColor='#306FAC' />
          <stop offset='0.84' stopColor='#3E8DC0' />
          <stop offset='1' stopColor='#4BA6D1' />
        </radialGradient>
      </defs>
    </>
  );
};
