import React from 'react';

export const PrintDesign = ({ className }) => {
  return (
    <>
      <g className={className}>
        <path
          d='M1.06179 19.4817L0.249433 19.4591C0.0376481 19.4529 -0.0789873 19.7046 0.0611798 19.8638L11.7564 33.1147L26.562 31.3934C26.562 31.3934 28.9796 32.4656 30.6258 31.0565C30.6974 31.1007 42.453 30.3232 45.4702 30.1219C45.6799 30.1075 45.7434 29.8292 45.5612 29.7234L44.4276 29.0671L13.8211 27.5502L1.06179 19.4817Z'
          fill='#FF5457'
        />
        <path
          d='M1.06179 19.4817L0.249433 19.4591C0.0376481 19.4529 -0.0789873 19.7046 0.0611798 19.8638L11.7564 33.1147L26.562 31.3934C26.562 31.3934 28.9796 32.4656 30.6258 31.0565C30.6974 31.1007 42.453 30.3232 45.4702 30.1219C45.6799 30.1075 45.7434 29.8292 45.5612 29.7234L44.4276 29.0671L13.8211 27.5502L1.06179 19.4817Z'
          fill='#4F4FCF'
        />
        <path
          d='M38.7517 22.24L44.5384 28.0551L43.3915 29.2033L27.1352 29.607L25.9023 21.253L31.3484 18.709L38.7517 22.24Z'
          fill='url(#paint0_linear_3544_56340)'
        />
        <path
          d='M18.7491 17.5244C18.7491 17.5244 23.703 12.2927 33.2569 12.7723C33.1382 12.7446 42.9345 22.4173 42.9345 22.4173C42.9345 22.4173 33.4032 20.4936 28.8135 27.7959L18.748 17.5244H18.7491Z'
          fill='url(#paint1_linear_3544_56340)'
        />
        <path
          d='M44.5391 28.0544C44.5391 28.0544 43.8066 28.5227 41.9865 28.4344C40.4927 28.3615 39.0389 27.925 37.719 27.2184C32.5216 24.4382 28.8138 27.7956 28.8138 27.7956L26.6356 26.37L23.8517 26.598L13.5643 31.0811L1.81275 18.1465L0.605469 19.6901L12.0419 32.4255C12.0419 32.4255 27.0469 30.6569 27.0039 30.7093C27.627 29.785 28.3749 29.6761 28.3749 29.6761C30.3168 29.6915 30.4979 30.395 30.4979 30.395L44.8522 29.671L44.5391 28.0534V28.0544Z'
          fill='#FF5457'
        />
        <path
          d='M44.5391 28.0544C44.5391 28.0544 43.8066 28.5227 41.9865 28.4344C40.4927 28.3615 39.0389 27.925 37.719 27.2184C32.5216 24.4382 28.8138 27.7956 28.8138 27.7956L26.6356 26.37L23.8517 26.598L13.5643 31.0811L1.81275 18.1465L0.605469 19.6901L12.0419 32.4255C12.0419 32.4255 27.0469 30.6569 27.0039 30.7093C27.627 29.785 28.3749 29.6761 28.3749 29.6761C30.3168 29.6915 30.4979 30.395 30.4979 30.395L44.8522 29.671L44.5391 28.0534V28.0544Z'
          fill='#4F4FCF'
        />
        <path
          d='M1.8125 18.1465C1.8125 18.1465 6.52907 18.7812 10.2849 16.6706C14.0408 14.56 17.0917 15.3396 18.7482 17.5241C18.7502 17.6504 28.8136 27.7956 28.8136 27.7956C28.8136 27.7956 26.0665 26.52 23.4299 28.1376C20.7934 29.7552 18.785 31.7723 13.564 31.0821C13.6264 31.2855 1.8125 18.1465 1.8125 18.1465Z'
          fill='url(#paint2_linear_3544_56340)'
        />
        <path
          d='M18.748 17.5245C18.748 17.5245 22.1806 7.77678 26.7969 2.24512C26.6046 2.26258 37.4772 10.5498 37.4772 10.5498C37.4772 10.5498 32.5172 17.8285 28.8135 27.796L18.748 17.5245Z'
          fill='url(#paint3_linear_3544_56340)'
        />
        <mask
          id='mask0_3544_56340'
          style={{ maskType: 'luminance' }}
          maskUnits='userSpaceOnUse'
          x='18'
          y='2'
          width='20'
          height='26'
        >
          <path
            d='M18.748 17.5245C18.748 17.5245 22.1806 7.77678 26.7969 2.24512C26.6046 2.26258 37.4772 10.5498 37.4772 10.5498C37.4772 10.5498 32.5172 17.8285 28.8135 27.796L18.748 17.5245Z'
            fill='white'
          />
        </mask>
        <g mask='url(#mask0_3544_56340)'>
          <path
            d='M23.3418 25.558C24.4591 24.5844 25.5538 23.5574 26.359 22.3126C27.1642 21.0668 27.6645 19.5704 27.4803 18.0966C27.3074 16.7049 26.5554 15.4663 26.0111 14.1753C25.4668 12.8843 25.1374 11.3519 25.7963 10.1164C26.2884 9.19207 27.243 8.61384 28.2037 8.20199C29.6995 7.56112 31.3201 7.21603 32.9458 7.19344C34.0211 7.17803 35.3113 7.20062 35.9006 6.29785'
            stroke='#4F4FCF'
            strokeWidth='0.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M33.2027 22.9897C32.5111 21.3033 28.8094 22.5173 28.8002 20.6933C28.7951 19.7206 28.3603 18.6042 28.8002 17.7374C29.453 16.4526 32.2011 16.8685 33.5178 16.2913C35.2111 15.5488 37.0302 15.0989 38.8728 14.9685'
            stroke='#4F4FCF'
            strokeWidth='0.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M25.3388 3.20386C25.2334 4.44042 25.1546 5.74169 25.6784 6.86527C26.3916 8.39763 28.0234 9.2244 29.4323 10.1528C32.3369 12.0683 34.6635 14.8536 36.0344 18.059'
            stroke='#4F4FCF'
            strokeWidth='0.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M37.4645 9.47442C36.8578 9.28237 35.4909 9.49188 34.8842 9.30085C32.9986 8.70517 30.9135 7.9431 30.0581 6.15501C29.607 5.21218 29.5824 4.10914 29.788 3.08415C29.9937 2.05915 30.4183 1.0927 30.8378 0.135498'
            stroke='#4F4FCF'
            strokeWidth='0.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
        <path
          d='M13.2315 0.802734L27.2134 13.2536C27.2134 13.2536 31.3192 19.0698 28.7153 27.8151L18.6499 17.5436C18.6499 17.5436 19.4326 9.47305 13.2305 0.802734H13.2315Z'
          fill='url(#paint4_linear_3544_56340)'
        />
        <path
          d='M38.4986 37.855L40.7218 37.0581L38.4986 36.2611L37.7047 34.0293L36.9107 36.2611L34.6875 37.0581L36.9107 37.855L37.7047 40.0868L38.4986 37.855Z'
          fill='#4F4FCF'
        />
        <path
          d='M31.3078 40.5894L32.7115 40.0862L31.3078 39.5829L30.8054 38.1738L30.3041 39.5829L28.9004 40.0862L30.3041 40.5894L30.8054 41.9996L31.3078 40.5894Z'
          fill='#4F4FCF'
        />
        <path
          d='M8.57869 9.20367L10.8019 8.40669L8.57869 7.6097L7.78475 5.37793L6.99081 7.6097L4.76758 8.40669L6.99081 9.20367L7.78475 11.4354L8.57869 9.20367Z'
          fill='#4F4FCF'
        />
        <path
          d='M13.9093 12.8209L15.3131 12.3177L13.9093 11.8144L13.408 10.4043L12.9067 11.8144L11.502 12.3177L12.9067 12.8209L13.408 14.23L13.9093 12.8209Z'
          fill='#4F4FCF'
        />
        <mask
          id='mask1_3544_56340'
          style={{ maskType: 'luminance' }}
          maskUnits='userSpaceOnUse'
          x='1'
          y='15'
          width='28'
          height='17'
        >
          <path
            d='M1.8125 18.1465C1.8125 18.1465 6.52907 18.7812 10.2849 16.6706C14.0408 14.56 17.0917 15.3396 18.7482 17.5241C18.7502 17.6504 28.8136 27.7956 28.8136 27.7956C28.8136 27.7956 26.0665 26.52 23.4299 28.1376C20.7934 29.7552 18.785 31.7723 13.564 31.0821C13.6264 31.2855 1.8125 18.1465 1.8125 18.1465Z'
            fill='white'
          />
        </mask>
        <g mask='url(#mask1_3544_56340)'>
          <path
            d='M7.96484 16.2363C8.46822 17.4195 9.0207 18.5975 9.82999 19.5948C10.6393 20.592 11.7361 21.4024 12.9955 21.6417C14.1844 21.8676 15.4009 21.5739 16.6061 21.466C17.8113 21.3582 19.1567 21.4917 19.9967 22.3657C20.6249 23.0179 20.8469 23.9618 20.9308 24.8656C21.0607 26.2736 20.9134 27.7064 20.5011 29.058C20.2279 29.9515 19.8678 31.0135 20.452 31.7416'
            stroke='#4F4FCF'
            strokeWidth='0.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M15.592 14.4854C16.5731 15.1447 17.363 16.0886 17.8408 17.1721C18.0004 17.5326 18.1262 17.9414 18.0055 18.3173C17.8899 18.6788 17.5676 18.9345 17.232 19.1081C16.5107 19.4809 15.6789 19.5703 14.8676 19.6062C12.67 19.7058 10.4621 19.4706 8.31659 18.9797C7.49912 18.7928 6.65095 18.5668 5.83348 18.7558C4.40419 19.0855 3.56012 20.5264 2.88281 21.8328'
            stroke='#4F4FCF'
            strokeWidth='0.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M4.69141 21.9627C6.25984 21.8385 7.85284 22.0202 9.35375 22.4978C10.1538 22.7515 10.9478 23.1017 11.5422 23.6964C12.4241 24.5776 12.7515 25.8737 12.8763 27.1164C13.037 28.7145 12.9234 30.3403 12.5418 31.8994'
            stroke='#4F4FCF'
            strokeWidth='0.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M25.7937 23.8198C24.8064 23.4039 23.7587 22.9941 22.6957 23.1286C21.248 23.3124 20.1359 24.4432 18.9992 25.3624C16.6552 27.2584 13.7496 28.4446 10.7539 28.7281'
            stroke='#4F4FCF'
            strokeWidth='0.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M17.2988 31.4557C17.617 31.0038 17.9352 30.5529 18.2544 30.101C19.2438 28.6981 20.4234 27.175 22.1187 26.9418C23.0129 26.8186 23.9256 27.0908 24.7123 27.5334C25.4991 27.9761 26.1805 28.5831 26.8558 29.1849'
            stroke='#4F4FCF'
            strokeWidth='0.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M18.7268 17.76C19.1013 16.4444 19.8553 15.2397 20.8723 14.3287C21.2109 14.0257 21.613 13.7412 22.0673 13.7412C22.5042 13.7412 22.8981 14.0062 23.207 14.3153C23.871 14.9798 24.2619 15.8651 24.5893 16.7474C25.4763 19.1373 25.9991 21.6567 26.2201 24.1965C26.304 25.165 26.3572 26.1798 26.8524 27.0158C27.718 28.4762 29.5954 28.8973 31.2651 29.1818'
            stroke='#4F4FCF'
            strokeWidth='0.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_3544_56340'
          x1='25.9023'
          y1='24.1585'
          x2='44.5384'
          y2='24.1585'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF5457' />
          <stop offset='0.77' stopColor='#FFE1DC' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_3544_56340'
          x1='25.9303'
          y1='24.9007'
          x2='35.7067'
          y2='15.1607'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF5457' />
          <stop offset='0.04' stopColor='#FF6062' />
          <stop offset='0.18' stopColor='#FF8787' />
          <stop offset='0.33' stopColor='#FFA7A6' />
          <stop offset='0.48' stopColor='#FFC1BD' />
          <stop offset='0.64' stopColor='#FFD2CE' />
          <stop offset='0.81' stopColor='#FFDDD8' />
          <stop offset='1' stopColor='#FFE1DC' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_3544_56340'
          x1='7.26878'
          y1='27.6323'
          x2='23.3881'
          y2='18.3622'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.23' stopColor='#FFE1DC' />
          <stop offset='1' stopColor='#FF5457' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_3544_56340'
          x1='19.9318'
          y1='19.5827'
          x2='37.0198'
          y2='9.7551'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF5457' />
          <stop offset='0.18' stopColor='#FF8282' />
          <stop offset='0.36' stopColor='#FFABA9' />
          <stop offset='0.53' stopColor='#FFC8C5' />
          <stop offset='0.67' stopColor='#FFDAD5' />
          <stop offset='0.78' stopColor='#FFE1DC' />
        </linearGradient>
        <linearGradient
          id='paint4_linear_3544_56340'
          x1='21.4062'
          y1='27.8151'
          x2='21.4062'
          y2='2.07011'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF5457' />
          <stop offset='0.78' stopColor='#FFE1DC' />
        </linearGradient>
      </defs>
    </>
  );
};
