import styles from "./usePrismIconsGlow.module.css";
import { useEffect, useState } from 'react';

export function usePrismIconsGlow(inView: boolean, itemsLength: number) {
  const [showIcons, setShowIcons] = useState(false);
  const [glowIndex, setGlowIndex] = useState(0);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (inView) {
      timeout = setTimeout(() => {
        setShowIcons(true);
      }, 2500);
    } else {
      setShowIcons(false);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [inView]);

  useEffect(() => {
    let glowAlternateTimeout: NodeJS.Timeout;

    if (inView && showIcons) {
      glowAlternateTimeout = setTimeout(() => {
        setGlowIndex((prevIndex) => (prevIndex + 1) % itemsLength);
      }, 2000);
    }

    return () => {
      clearTimeout(glowAlternateTimeout);
    };
  });

  return { showIcons, glowIndex };
}

export const showStyle = styles.showStyle;

export const hiddenStyle = styles.hiddenStyle;
